<template>
<div class="">
      <div class="d-flex justify-content-center" style="margin-top:10px">  
        <TopBar></TopBar>
      </div>
    <div class="home" ref="formContainer">
      <div class="main-content">
      <AddBet></AddBet>
        <div class="card">
          <div class="card-body">
            <ListBets></ListBets>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import ListBets from '@/components/Home/ListBets.vue'
import AddBet from '@/components/Home/AddBet.vue'
import TopBar from '@/components/Home/TopBar.vue'

export default {
  name: 'Home',
  components: {
    ListBets,
    AddBet,
    TopBar
  }
}
</script>

<style>
.title{
    text-align: center;
}
.home{
  padding: 30px;
}

</style>
