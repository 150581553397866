<template>
  <div class="">
    <div class="card-footer" v-if="showHeadToHead">
      <div class="row title-center">
        <h3>Confronto direto</h3>
        Quantidade de jogos: {{ dados.head_to_head[clubea].partidas_jogadas }}
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col">
          Cloud
          <k-progress
            :percent="dados.head_to_head.drake_total.cloud"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Mountain
          <k-progress
            :percent="dados.head_to_head.drake_total.mount"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          Ocean
          <k-progress
            :percent="dados.head_to_head.drake_total.ocean"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Inferno
          <k-progress
            :percent="dados.head_to_head.drake_total.inferno"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col"></div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row title-center">
        <h3>{{ clubea }}</h3>
        Quantidade de jogos: {{ dados.drake[clubea].partidas }}
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col">
          Cloud
          <k-progress
            :percent="dados.drake[clubea].cloud"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Mountain
          <k-progress
            :percent="dados.drake[clubea].mount"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          Ocean
          <k-progress
            :percent="dados.drake[clubea].ocean"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Inferno
          <k-progress
            :percent="dados.drake[clubea].inferno"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          Quimtec
          <k-progress
            :percent="dados.drake[clubea].chemt"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Hextech
          <k-progress
            :percent="dados.drake[clubea].hexte"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col"></div>
      </div>
      <div class="row title-center">
        <h3>{{ clubeb }}</h3>
        Quantidade de jogos: {{ dados.drake[clubeb].partidas }}
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col">
          Cloud
          <k-progress
            :percent="dados.drake[clubeb].cloud"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Mountain
          <k-progress
            :percent="dados.drake[clubeb].mount"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          Ocean
          <k-progress
            :percent="dados.drake[clubeb].ocean"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Inferno
          <k-progress
            :percent="dados.drake[clubeb].inferno"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          Quimtec
          <k-progress
            :percent="dados.drake[clubeb].chemt"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Hextech
          <k-progress
            :percent="dados.drake[clubeb].hexte"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col"></div>
      </div>
      <div class="row title-center">
        <h3>Chances somadas</h3>
        <h5 class="text-muted">(Chance 1 + Chance 2) / 2</h5>
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col">
          Cloud
          <k-progress
            :percent="
              (dados.drake[clubeb].cloud + dados.drake[clubea].cloud) / 2
            "
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Mountain
          <k-progress
            :percent="
              (dados.drake[clubeb].mount + dados.drake[clubea].mount) / 2
            "
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          Ocean
          <k-progress
            :percent="
              (dados.drake[clubeb].ocean + dados.drake[clubea].ocean) / 2
            "
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Inferno
          <k-progress
            :percent="
              (dados.drake[clubeb].inferno + dados.drake[clubea].inferno) / 2
            "
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          Quimtec
          <k-progress
            :percent="
              (dados.drake[clubeb].chemt + dados.drake[clubea].chemt) / 2
            "
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
          Hextech
          <k-progress
            :percent="
              (dados.drake[clubeb].hexte + dados.drake[clubea].hexte) / 2
            "
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        dados: Object,
        clubea: String,
        clubeb:String,
    },
    mounted() {
      if(this.dados.head_to_head[0] == 'não existe confronto direto') {
        this.showHeadToHead = false
      } else {
        this.showHeadToHead = true
      }
      console.log(this.showHeadToHead)
    },
    data: () => {
        return {
            line_height: 12,
            showHeadToHead: false
        }
    },
}
</script>

<style>
.versusred {
  color: red;
}
</style>