<template>
    <div class="">
        <div class="card-footer card-footer-boxed">
      <div class="row">
        <div class="col">
          <h5 class="title-center text-muted">{{clubea}}</h5>
        </div>
        <div class="col">
           <h5 class="title-center text-muted">{{clubeb}}</h5>
        </div>
      </div>
      <div class="row" >
          <div class="col-md-6">
            First Blood: 
            <k-progress
            :percent="resultado[clubea].porcent_fb"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />
            <hr>     
            First Tower: 
            <k-progress
            :percent="resultado[clubea].porcent_ft"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />  
            <hr>       
            Handicap Kills +:
            <k-progress
            :percent="resultado[clubea].handicap_kill_positivo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />  
            <hr>       
            Handicap Kills -: 
            <k-progress
            :percent="resultado[clubea].handicap_kill_negativo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>         
            Total Dragon -4,5:
                        <k-progress
            :percent="resultado[clubea].total_dragon_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />  
            <hr>         
            Total Dragon +4,5 :   
                        <k-progress
            :percent="resultado[clubea].total_dragon_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />  
            <hr>   
            Total Kills Acima : 
                        <k-progress
            :percent="resultado[clubea].kill_26_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />      
            <hr>      
            Total Kills Abaixo :
                        <k-progress
            :percent="resultado[clubea].kill_26_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />   
            <hr>      
            Tower Acima :
                        <k-progress
            :percent="resultado[clubea].tower_12_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />      
            <hr>      
            Tower Abaixo:   
                        <k-progress
            :percent="resultado[clubea].tower_12_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />    
            <hr>     
            Gold Acima {{gold}} :
                        <k-progress
            :percent="resultado[clubea].gold_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />      
            <hr>      
            Gold Abaixo {{gold}}:
                        <k-progress
            :percent="resultado[clubea].gold_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />    
            <hr>  
            First Drake : 
                        <k-progress
            :percent="resultado[clubea].first_drake"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>        
            First Baron :
                        <k-progress
            :percent="resultado[clubea].first_baron"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>         
            Fisrt Herald :
                        <k-progress
            :percent="resultado[clubea].first_herald"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />      
            <hr>    
            Handicap Gold + :
                        <k-progress
            :percent="resultado[clubea].handicap_gold_positivo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>      
            Handicap Gold - : 
                        <k-progress
            :percent="resultado[clubea].handicap_gold_negativo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>        
            Mata pelo menos 1 dragon por partida :
                        <k-progress
            :percent="resultado[clubea].at_least_dragon"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>        
            Baron Under :
                        <k-progress
            :percent="resultado[clubea].baron_under"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>    
            Baron Over : 
                        <k-progress
            :percent="resultado[clubea].baron_over"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>    
            Under Kill :
                        <k-progress
            :percent="resultado[clubea].total_kill_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />        
            <hr>   
            Over Kill : 
                        <k-progress
            :percent="resultado[clubea].total_kill_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />           
            <hr>    
            Abaixo de {{tempoJogo}} :
            <k-progress
            :percent="resultado[clubea].under_time"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />        
            <hr>   
            Acima de {{tempoJogo}} : 
                        <k-progress
            :percent="resultado[clubea].over_time"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />             
            </div>
            <div class="col-md-6">
            First Blood: 
            <k-progress
            :percent="resultado[clubeb].porcent_fb"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />
            <hr>     
            First Tower: 
            <k-progress
            :percent="resultado[clubeb].porcent_ft"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />  
            <hr>       
            Handicap Kills +:
            <k-progress
            :percent="resultado[clubeb].handicap_kill_positivo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />  
            <hr>       
            Handicap Kills -: 
            <k-progress
            :percent="resultado[clubeb].handicap_kill_negativo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>         
            Total Dragon -4,5:
                        <k-progress
            :percent="resultado[clubeb].total_dragon_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />  
            <hr>         
            Total Dragon +4,5 :   
                        <k-progress
            :percent="resultado[clubeb].total_dragon_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />  
            <hr>   
            Total Kills Acima : 
                        <k-progress
            :percent="resultado[clubeb].kill_26_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />      
            <hr>      
            Total Kills Abaixo :
                        <k-progress
            :percent="resultado[clubeb].kill_26_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />   
            <hr>      
            Tower Acima :
                        <k-progress
            :percent="resultado[clubeb].tower_12_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />      
            <hr>      
            Tower Abaixo:   
                        <k-progress
            :percent="resultado[clubeb].tower_12_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />    
            <hr>     
            Gold Acima :
                        <k-progress
            :percent="resultado[clubeb].gold_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />      
            <hr>      
            Gold Abaixo :
                        <k-progress
            :percent="resultado[clubeb].gold_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />    
            <hr>  
            First Drake : 
                        <k-progress
            :percent="resultado[clubeb].first_drake"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>        
            First Baron :
                        <k-progress
            :percent="resultado[clubeb].first_baron"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>         
            Fisrt Herald :
                        <k-progress
            :percent="resultado[clubeb].first_herald"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />      
            <hr>    
            Handicap Gold + :
                        <k-progress
            :percent="resultado[clubeb].handicap_gold_positivo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>      
            Handicap Gold - : 
                        <k-progress
            :percent="resultado[clubeb].handicap_gold_negativo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>        
            Mata pelo menos 1 dragon por partida :
                        <k-progress
            :percent="resultado[clubeb].at_least_dragon"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>        
            Baron Under :
                        <k-progress
            :percent="resultado[clubeb].baron_under"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>    
            Baron Over : 
                        <k-progress
            :percent="resultado[clubeb].baron_over"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />     
            <hr>    
            Under Kill :
                        <k-progress
            :percent="resultado[clubeb].total_kill_abaixo"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />        
            <hr>   
            Over Kill : 
                        <k-progress
            :percent="resultado[clubeb].total_kill_acima"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />           
            <hr>    
            Abaixo de {{tempoJogo}} :
            <k-progress
            :percent="resultado[clubeb].under_time"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />        
            <hr>   
            Acima de {{tempoJogo}} : 
                        <k-progress
            :percent="resultado[clubeb].over_time"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
            />             
            </div>
  </div>
  </div>
    </div>
</template>

<script>
export default {
    props:{
        resultado: Object,
        clubea: String,
        clubeb:String,
        tempoJogo: String,
        gold: String
    },
    data: () => {
        return {
            line_height: 12,
        }
    }
}
</script>

<style>

</style>