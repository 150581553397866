<template>
  <div>
    <div v-for="partida in dados" :key="partida.id" class="card">
      <router-link
        :to="{
          name: 'AnalisePartida',
          params: {
            clubea: partida.clubea,
            clubeb: partida.clubeb,
          },
        }"
      >
        <div class="future-event">
          <div class="event-time">
            {{ get_data(partida.data) }}
          </div>
          <div class="event-time">
            {{ $moment(partida.data).fromNow() }}
          </div>
          <!-- <div class="">
            <span class="pulse"></span>
            <router-link
              :to="{
                name: 'AnalisePartida',
                params: { clubea: '21312', clubeb: '23123' },
              }"
            >
              <span style="margin-left: 10px">Live</span>
            </router-link>
          </div> -->
          <div class="teams">
            <div class="team team1">
              <img :src="partida.clubea_image" alt="" />
              <div class="team-info">
                <span class="name"> {{ partida.clubea }}</span>
              </div>
            </div>
            <div class="versus">VS</div>
            <div class="team team2">
              <img :src="partida.clubeb_image" alt="" />
              <div class="team-info">
                <span class="name"> {{ partida.clubeb }}</span>
              </div>
            </div>
          </div>
          <div class="league">
            <div class="name">
              <span>{{ partida.campeonato }}</span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
export default {
    data: function () {
        return {
            dados: {}
        }
    },
    mounted(){
        axios.get(
          'view_agenda_panda')
        .then(resp =>{
            this.dados= resp.data
            this.$notify('Clique em algum time para ver as estatisticas para profitar!', 'success')
        })
        .catch(err => { 
          console.log(err)            
          this.$notify('Limite excedido de busca, tente novamente mais tarde!', 'error')
        })
    },
    methods:{
      get_data(value) {
        return this.$moment(value)
                .format("DD-MM")
      }
    }

}
</script>

<style>
a {
  color: white;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: #00d97e;
  background-color: transparent;
  text-decoration: underline;
}
.future-event {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: calc(((100% - 442px) / 12) * 12 + 374px);
  height: 100px;
  margin: 0 34px;
}
.event-time {
  display: inline-block;
  flex: 0 0 110px;
}
.teams {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  position: relative;
}
.teams img {
  margin: 16px 0;
  box-sizing: content-box;
  width: 40px;
  height: 40px;
}
@media (max-width: 1075px) {
  .teams img {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 720px) {
  .teams img {
    width: 24px;
    height: 24px;
  }
}
.teams .team1 {
  text-align: right;
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.teams .team2 {
  text-align: left;
  justify-content: flex-start;
}
.teams .team {
  display: flex;
  align-items: center;
  width: 45%;
}
.team-info {
  padding-right: 12px;
}
.versus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  color: #8fa3b0;
  letter-spacing: 0.1rem;
  font-weight: bold;
}
.league {
  flex: 0 0 110px;
  color: #8fa3b0;
  letter-spacing: 0.1rem;
  font-weight: bold;
  text-align: right;
}
.teams .h2 {
  display: block;
}
.pulse {
  margin: 20px;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff0000;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(235, 59, 59, 0.4);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 53, 53, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 20, 20, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 20, 20, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 20, 20, 0);
    box-shadow: 0 0 0 10px rgba(255, 20, 20, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 20, 20, 0);
    box-shadow: 0 0 0 0 rgba(255, 20, 20, 0);
  }
}
</style>