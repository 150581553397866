<template>
  <div class="card-footer card-footer-boxed">
    <div class="card-footer title-center" style="max-heigth: 300px">
      <div class="row">
        <div class="col-md-6">
          <h3 class="text-muted">Campeões mais pickados pelos times.</h3>
          <ul class="reverseorder">
            <li v-for="(x, value) in porra" :key="value">
              {{ x }} - {{ value }}
              <img
                class="img-avatar"
                :src="
                  'https://gol.gg/_img/champions_icon/' +
                  getNameChampion(value) +
                  '.png'
                "
              />
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <h3 class="text-muted">Campeões mais banidos pelos times.</h3>
          <ul class="reverseorder">
            <li v-for="(x, value) in caralho" :key="value">
              {{ x }} - {{ value }}
              <img
                class="img-avatar"
                :src="
                  'https://gol.gg/_img/champions_icon/' +
                  getNameChampion(value) +
                  '.png'
                "
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        dados: Object,
        bans: Object
    },
    mounted (){
        var sortedList = {}
        Object.keys(this.dados).sort((a,b) => this.dados[a]-this.dados[b]).forEach((key) => {
        sortedList[key] = this.dados[key]; })
        this.porra = sortedList
        var sortedBans = {}
        Object.keys(this.bans).sort((a,b) => this.bans[a]-this.bans[b]).forEach((key) => {
        sortedBans[key] = this.bans[key]; })
        this.caralho = sortedBans
    },
    data: () => {
        return {
            line_height: 12,
            porra: {},
            caralho: {},
        }
    },
    methods: {
      getNameChampion: function (name){
        var result = name.replace(" ", "")
        return result
      }
    }
}
</script>

<style>
.img-avatar {
  width: 25px;
  height: 25px;
  border: 0px;
}
.reverseorder {
  display: flex;
  flex-direction: column-reverse;
}
.versusred {
  color: red;
  margin-left: 5px;
  margin-right: 8px;
}
.versusgreen {
  color: green;
  margin-left: 5px;
  margin-right: 8px;
}
.versus {
  max-width: 30%;
  max-height: 50%;
}
.head {
  width: 60%;
}
</style>