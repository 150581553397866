<template>
  <div class="container-fluid">
      <div class="row justify-content-center">
            <H2>INSERE PARTIDAS PARA O BOT ANALISAR</H2>
      </div>
      <div class="row justify-content-center">
    <div class="col-2">
        <span>Blue</span>
        <v-select class="style-chooser" v-model="blue" :options="clubes"></v-select>
    </div>
    <div class="col-2">
        <span>Red</span>
        <v-select class="style-chooser" v-model="red" :options="clubes"></v-select>
    </div>
    </div>
      <div class="row justify-content-center">
    <div class="col-2">
        <span>Vencedor</span>
        <v-select class="style-chooser" v-model="vencedor" :options="[red,blue]"></v-select>
    </div>
    <div class="col-2">
        <span>Split</span>
        <v-select class="style-chooser" v-model="split" :options="['1','2']"></v-select>
    </div>
    </div>
    
    <div class="row justify-content-center">
            <div class="col-6">
        <span>Link</span>
        <div class="input-group input-group-merge">
            <input type="text" class="form-control form-control-prepended" v-model="link">
            <div class="input-group-prepend">
                <div class="input-group-text">
            <span class="fe fe-eye"></span>
            </div>
        </div>
        </div>
    </div>
    </div>
        <div class="row justify-content-center">
        <div class="col-1" style="margin-top: 30px;">
            <button type="button" class="btn btn-success btn-lg" @click="enviar">Enviar!!</button>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'

export default {
      components: {
    vSelect
  },
    data: function(){
        return {
            clubes: [],
            blue: '',
            red: '',
            vencedor: '',
            link: '',
            split: 1
        }
    },
    mounted() {
    axios.get('list_addBet')
    .then(resp => {
      var clubesArr = Object.values(resp.data.clubes)
      var result = clubesArr.map(x => (x.name))
      this.clubes = result
    })
    .catch(err => {
      console.log(err)
    })
    },
    methods: {
        enviar: function (){
            axios.post('analisa_partida', {
                blue: this.blue,
                red: this.red,
                vencedor: this.vencedor,
                link: this.link,
                split: this.split
            })
            .then(resp => {
                console.log(resp)
            })
            .catch(err => {
                console.log(err)
            })
        }
    }
}
</script>

<style>

</style>