import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(Vuex)

// Make Axios play nice with Django CSRF
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const store = new Vuex.Store({
  state: {
    authUser: {},
    id: localStorage.getItem('id'),
    username: localStorage.getItem('username'),
    isAuthenticated: false,
    jwt: localStorage.getItem('token'),
    endpoints: {
      // TODO: Remove hardcoding of dev endpoints
      obtainJWT: 'https://betlol.com.br/painel/login/',
      refreshJWT: 'https://betlol.com.br/painel/refresh-token/',
      baseUrl: 'https://betlol.com.br/painel/'
      // obtainJWT: 'http://localhost:8000/painel/login/',
      // refreshJWT: 'http://localhost:8000/painel/refresh-token/',
      // baseUrl: 'http://localhost:8000/painel/'
    },
    counter: 0,
    listBet: {},
    apostado: 0,
    lucro: 0,
    lucro_mensal: 0,
    lucro_hoje: 0
  },
  mutations: {
    autenticar(state) {
      state.isAuthenticated = true
    },
    attListBet(state, obj) {
      state.listBet = obj
    },
    addApostado(state, valor) {
      state.apostado = parseFloat(state.apostado) + parseInt(valor)
    },
    addLucro(state, valor) {
      state.lucro = parseFloat(state.lucro) + parseFloat(valor)
    },
    excludeLucro(state, valor) {
      state.lucro = parseFloat(state.lucro) - parseFloat(valor)
    },
    setAuthUser(state, {
      authUser,
      isAuthenticated
    }) {
      Vue.set(state, 'authUser', authUser)
      Vue.set(state, 'isAuthenticated', isAuthenticated)
    },
    updateToken(state, newToken) {
      localStorage.setItem('token', newToken)
      state.jwt = newToken
    },
    removeToken(state) {
      localStorage.removeItem('token')
      state.jwt = null
    }
  },
  actions: {
    checkToken() {
      const token = localStorage.getItem('token')
      if (token) {
        var test = VueJwtDecode.decode(token)
        var currentTime = new Date().getTime() / 1000
        if (currentTime > test.exp) {
          console.log('expirou')
          this.$router.push({
            name: 'Login'
          })
        } else {
          console.log('ainda esta valido')
          console.log(token)
          axios.post(this.state.endpoints.refreshJWT, {
              token: token
            })
            .then((response) => {
              this.state.jwt = response.data
              // this.state.isAuthenticated = true
              console.log('TOKEN ATUALIZADO COM SUCESSO')
            })
        }
      } else if (token === null) {
        console.log('VSF TA NULO')
        this.$router.push({
          name: 'Login'
        })
      } else {
        this.$router.push({
          name: 'Login'
        })
      }
    }
  }
})

export {
  store
}