<template>
  <!-- <nav class="navbar navbar-expand-lg navbar-dark navbar-vibrant" id="topnav"> -->
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
    id="sidebar"
  >
    <div class="container-fluid">
      <!-- Toggler -->
      <button
        class="navbar-toggler mr-auto"
        type="button"
        data-toggle="collapse"
        data-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fe fe-align-justify" style="color: white"></i>
      </button>

      <!-- Brand -->
      <a class="navbar-brand" href="../index.html">
        <img
          src="../../public/img/oie_transparent.png"
          alt="..."
          class="navbar-brand-img"
        />
      </a>

      <div class="collapse navbar-collapse">
        <!-- User -->
        <div class="navbar-user d-none d-md-flex">
          <!-- Dropdown -->
          <!--       
        <div class="dropdown mr-4 d-none d-lg-flex">
         
          <a
            href="#"
            class="navbar-user-link"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="icon active">
              <i class="fe fe-bell"></i>
            </span>
          </a>

          
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-card">
            <div class="card-header">
             
              <h5 class="card-header-title">Notificações</h5>
            </div>
          
            <div class="card-body">
            
              <div
                class="list-group list-group-flush list-group-activity my-n3"
              >
                <a class="list-group-item text-reset" href="#!">
                  <div class="row">
                    <div class="col-auto"></div>
                  </div>
                
                </a>
              </div>
            </div>
          </div>
         
        </div> -->

          <!-- Dropdown -->
          <div class="dropup">
            <!-- Toggle -->
            <a
              href="#"
              class="dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <!-- <div class="avatar avatar-sm avatar-online">
                <img
                  src="../../public/img/avatars/profiles/avatar-6.jpg"
                  alt="..."
                  class="avatar avatar-sm avatar-online"
                />
              </div> -->
              <div class="d-flex flex-column">
                <span>{{ $store.state.username }}</span>
                <small class="text-muted">v1.0.56</small>
              </div>
            </a>

            <!-- Menu -->
            <div class="dropdown-menu dropdown-menu-right">
              <router-link :to="{ name: 'Profile' }">
                <a href="#" class="dropdown-item">Profile</a>
              </router-link>
              <hr class="dropdown-divider" />
              <a href="#" class="dropdown-item" @click.prevent="logout"
                >Logout</a
              >
            </div>
          </div>
        </div>

        <!-- Collapse -->
        <div
          class="collapse navbar-collapse mr-auto order-lg-first"
          id="navbar"
        >
          <!-- Navigation -->
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="/bets">
                <i class="fe fe-home"></i> Bets
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="/live">
                <i class="fe fe-fast-forward"></i> Programação
              </a>
            </li> -->
            <!-- <li class="nav-item">
            <a class="nav-link" href="/balancodiario">
              <i class="fe fe-file"></i> Estatisticas
            </a>
          </li> -->
            <li class="nav-item">
              <a class="nav-link" href="/analise-partida">
                <i class="fe fe-arrow-up-circle"></i>Análise de Partidas
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/analise-partida-boost">
                <i class="fe fe-arrow-up-circle"></i>Análise de Partidas BOOST
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="/call-streamer">
                <i class="fe fe-user-check"></i>Call Streamer
              </a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" href="/calculadora-odds">
                <i class="fe fe-trending-up"></i>Calculadora de Odds
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/contribua">
                <i class="fe fe-thumbs-up"></i>Nos ajude a crescer!
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/suporte">
                <i class="fe fe-help-circle"></i> Suporte
              </a>
            </li>
            <!-- <li class="nav-item d-md-none">
              <a class="nav-link" href="#sidebarModalActivity" data-toggle="modal">
                <span class="fe fe-bell"></span> Notifications
              </a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <!-- / .container -->
  </nav>
</template>

<script>
export default {
  methods: {
    logout () {
      this.$store.commit('removeToken')
      this.$router.push({name: 'Welcome'})
    }
  }

}
</script>

<style scoped>
a {
  color: white;
}
</style>