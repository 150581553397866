<template>
  <div class="">
    <div class="container-fluid">
      <!-- <div class="title-center">
        <a href="https://uol.com.br">
          <img
            src="../../assets/images/riv.png"
            class="imagepin21nacle"
            height="100px"
            style="margin-top: 1px"
          />
        </a>
      </div> -->
      <button
        type="button"
        id="btn-add-bet"
        class="btn btn-outline-success mb-2"
        style="margin-right: 8px"
        @click="switchaddBet"
      >
        Adicionar bet!
      </button>
      <a href="/balancodiario">
        <button
          type="button"
          class="btn btn-outline-success mb-2"
          style="margin-right: 8px"
        >
          Estatisticas
        </button>
      </a>
      <div class="card" v-if="showaddBet">
        <div class="card-body">
          <div class="row">
            <div class="col-2">
              <span>Data</span>
              <flat-pickr
                v-model="dateBet"
                placeholder="Data"
                class="form-control"
                :config="dataConfig"
              >
              </flat-pickr>
            </div>
            <div class="col-2">
              <span>Competição</span>
              <v-select
                class="style-chooser"
                v-model="competicao"
                :options="competicoesList"
                @input="getClubes"
              >
              </v-select>
            </div>
            <div class="col-2">
              <span>Estilo</span>
              <v-select
                class="style-chooser"
                v-model="tipoBet"
                :options="tiposBet"
              >
              </v-select>
            </div>
            <div class="col-2">
              <span>Vencedor</span>
              <v-select
                class="style-chooser"
                v-model="vencedor"
                :options="clubes"
              ></v-select>
            </div>
            <div class="col-2">
              <span>Perdedor</span>
              <v-select
                class="style-chooser"
                v-model="perdedor"
                :options="clubes"
              ></v-select>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <span>Entrada</span>
              <input
                type="text"
                class="form-control"
                v-model="entrada"
                @input="calculaLucro"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="col-2">
              <span>ODD</span>
              <input
                type="text"
                class="form-control"
                v-model="odd"
                @input="calculaLucro"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="col-2">
              <span>Resultado</span>
              <select
                class="custom-select"
                v-model="resultadoBet"
                @change="calculaLucro"
              >
                <option>AGUARDANDO</option>
                <option>CERTO</option>
                <option>ERRADO</option>
              </select>
            </div>
            <div class="col-2">
              <span>Lucro</span>
              <input
                disabled
                type="text"
                class="form-control"
                v-model="lucro"
              />
            </div>
            <div class="col-2">
              <span>Timer que aposta foi feita</span>
              <the-mask
                v-model="tempoJogo"
                class="form-control"
                :mask="['##:##']"
              />
            </div>
            <!-- <div class="col-2">
              <span>Call</span>
              <select class="custom-select" v-model="call" >
                <option>NOBODY</option>
                <option>HARPON</option>
                <option>LEP</option>
                <option>YAN</option>
                <option>BAIANO</option>
              </select>
            </div> -->
            <div class="col-2" style="margin-top: 20px">
              <div v-if="loadedBet">
                <atom-spinner :size="50" :color="'#00d97e'" />
              </div>
              <div class="" v-else>
                <button
                  type="button"
                  id="btn-add-bet"
                  class="btn btn-outline-success mb-2"
                  @click="addBet"
                >
                  Enviar!
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <input
              v-model="observacoes"
              type="text"
              class="form-control form-control-flush"
              placeholder="Comentários: Digite quaisquer outras informações que precisar, aqui!"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
// import 'flatpickr/dist/themes/dark.css'
import vSelect from 'vue-select'
import '../../../node_modules/vue-select/dist/vue-select.css'
import axios from 'axios'
import {Portuguese} from 'flatpickr/dist/l10n/pt.js'
import {AtomSpinner} from 'epic-spinners'

export default {
  components: {
    flatPickr,
    vSelect,
    AtomSpinner
  },
  data: function () {
    return {
      tempoJogo: '',
      observacoes: '',
      showaddBet: true,
      dateBet: null,
      competicoes: '',
      competicoesList: [],
      competicao: '',
      vencedor: '',
      perdedor: '',
      entrada: 0,
      odd: 1,
      resultadoBet: 'CERTO',
      lucro: 0,
      clubes: [],
      tiposBet: [],
      tipoBet: '',
      listBets: {},
      call: '',
      dataConfig: {
        locale: Portuguese
      },
      isLoading: false,
      loadedBet: false
    }
  },
  mounted() {
    axios.get('list_addBet')
    .then(resp => {
      this.competicoes = resp.data.competicoes
      var dataArray = []
      for(var o in resp.data.competicoes) {
          dataArray.push(resp.data.competicoes[o].name);
      }
      this.competicoesList = dataArray
      
      var dataArrayTipoBet = []
      for(var i in resp.data.tiposBet) {
          dataArrayTipoBet.push(resp.data.tiposBet[i].name);
      }
      this.tiposBet = dataArrayTipoBet
      
      // var clubesArr = Object.values(resp.data.clubes)
      // var result = clubesArr.map(x => (x.name))
      // this.clubes = result
    })
    .catch(err => {
      console.log(err)
    })
  },
methods: {
  switchaddBet: function() {
    this.showaddBet = !this.showaddBet
  },
  addBet: function() {    
    this.loadedBet = true
    const config = {
            headers: {
        // Set your Authorization to 'JWT', not Bearer!!!
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }
    axios.post('create_bet', {
      call: this.call,
      data: this.dateBet,
      competicao: this.competicao,
      estilo: this.tipoBet,
      vencedor: this.vencedor,
      perdedor: this.perdedor,
      entrada: this.entrada,
      odd: this.odd,
      resultado: this.resultadoBet,
      lucro: this.lucro,
      comentario: this.observacoes,
      tempoJogo: this.tempoJogo
    }, config)
    .then(resp => {
      this.$store.commit('attListBet',resp.data)
      this.$store.commit('addApostado', this.entrada)
      if (this.tipoBet === 'CERTO') {this.$store.commit('addLucro', this.lucro)}
      this.loadedBet = false
      axios.get('list_gasto_lucro', config)
      .then(resp => {
        var apostado = resp.data.apostado
        var lucro = resp.data.lucro
        var lucro_hoje = resp.data.lucro_hoje
        var lucro_mensal = resp.data.lucro_mensal

        this.$store.state.apostado = apostado
        this.$store.state.lucro = lucro
        this.$store.state.lucro_hoje = lucro_hoje
        this.$store.state.lucro_mensal = lucro_mensal
      })
    }).catch(err => {
      console.log(err)
      this.$notify('Error! Revise os campos e veja veja se não esqueceu de preencher algum.', 'error')
      this.loadedBet = false
    })
  },
  calculaLucro: function() {
  if (this.resultadoBet === 'CERTO') {
    var ganho = this.entrada * this.odd
    var lucro = ganho - this.entrada
    this.lucro = lucro.toFixed(2)
  } else if ( this.resultadoBet === 'ERRADO') {
    this.lucro = this.entrada * -1    
  } else {
    this.lucro = 0
  }
    },
  routerBalanco: function(){
      this.$router.push('/balancodiario')
    },
  isNumber: function (evt) {
      evt = (evt) || window.event
      var charCode = (evt.wich) ? evt.wich : evt.keyCode
      if (charCode === 44 || charCode === 45 || charCode === 47) {
        evt.preventDefault()
        this.$notify('Use apenas ponto nos valores!', 'error')
      } else {
        return true
      }
    },
  getClubes: function() {
      const config = {
            headers: {
        // Set your Authorization to 'JWT', not Bearer!!!
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
          },
        }
      if (this.competicao){ 
      axios.get(`filtra_clube_por_competicao/${this.competicao}/`,config)
      .then(resp => {
        this.clubes = resp.data
      })
      .catch(err => {
        console.log(err)
      })
      }
    }
  }
}
</script>

<style >
#btn-add-bet {
  margin-bottom: 20px;
}
.style-chooser .vs__selected {
  color: white;
}
.style-chooser .vs__dropdown-toggle {
  background-color: #1e3a5c;
}
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: white;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: #1e3a5c;
  background-clip: padding-box;
  border: 1px solid #12263f;
  border-radius: 0.375rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #ffffff;
}
.form-control:focus {
  color: #ffffff;
  background-color: #1e3a5c;
  border-color: #2c7be5;
  outline: 0;
  box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
  color: #95aac9;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #95aac9;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #95aac9;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #95aac9;
  opacity: 1;
}
.form-control::placeholder {
  color: #95aac9;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #1e3a5c;
  opacity: 1;
}
</style>