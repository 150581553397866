<template>
  <div>
    <SideBar></SideBar>

    <div class="main-content">
      <div class="container-fluid">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SideBar from '@/components/SideBar.vue'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: 'MainLayout',
  components: {
    SideBar
  },
    mounted () {
    // this.token = this.$store.state.jwt
    const token = localStorage.getItem('token')
    if (token) {
      var test = VueJwtDecode.decode(token)
      // console.log(test.exp)
      var currentTime = new Date().getTime() / 1000
      // console.log(currentTime)
      if (currentTime > test.exp) {
        // console.log('expirou')
        this.$router.push({ name: 'Login' })
      } else {
        // console.log('ainda esta valido')
        this.$store.commit('autenticar')        
        axios.post(this.$store.state.endpoints.refreshJWT, { token: token })
          .then((response) => {
            this.$store.state.jwt = response.data
            // console.log('TOKEN ATUALIZADO COM SUCESSO')
          })
      }
    } else if (token === null) {
      // console.log('NULO')
      this.$router.push({ name: 'Login' })
    } else {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style>
.title {
  text-align: center;
}
</style>
