<template>
  <div class="">
    <div class="card-footer card-footer-boxed">
      <div class="row">
        <div class="col">
          <h5 class="title-center text-muted">{{ clubea }}</h5>
        </div>
        <div class="col">
          <h5 class="title-center text-muted">{{ clubeb }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div v-if="resultado[clubea].at_least_dragon">
            Mata 1 dragon por partida:
            <k-progress
              :percent="resultado[clubea].at_least_dragon"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].baron_over">
            Baron +1.5:
            <k-progress
              :percent="resultado[clubea].baron_over"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].baron_under">
            Baron -1.5:
            <k-progress
              :percent="resultado[clubea].baron_under"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].porcent_fb">
            First Blood:
            <k-progress
              :percent="resultado[clubea].porcent_fb"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].porcent_ft">
            First Tower:
            <k-progress
              :percent="resultado[clubea].porcent_ft"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].tower_11_5_abaixo">
            Tower -11.5:
            <k-progress
              :percent="resultado[clubea].tower_11_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].tower_11_5_acima">
            Tower +11.5:
            <k-progress
              :percent="resultado[clubea].tower_11_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].tower_12_5_abaixo">
            Tower -12.5:
            <k-progress
              :percent="resultado[clubea].tower_12_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].tower_12_5_acima">
            Tower +12.5:
            <k-progress
              :percent="resultado[clubea].tower_12_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].total_dragon_abaixo_4_5">
            Dragon -4.5:
            <k-progress
              :percent="resultado[clubea].total_dragon_abaixo_4_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].total_dragon_acima_4_5">
            Dragon +4.5:
            <k-progress
              :percent="resultado[clubea].total_dragon_acima_4_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].total_dragon_abaixo_5_5">
            Dragon -5.5:
            <k-progress
              :percent="resultado[clubea].total_dragon_abaixo_5_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].total_dragon_acima_5_5">
            Dragon +5.5:
            <k-progress
              :percent="resultado[clubea].total_dragon_acima_5_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_3_5">
            Handicap Kill -3.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_3_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_4_5">
            Handicap Kill -4.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_4_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_5_5">
            Handicap Kill -5.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_5_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_6_5">
            Handicap Kill -6.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_6_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_7_5">
            Handicap Kill -7.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_7_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_8_5">
            Handicap Kill -8.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_8_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_9_5">
            Handicap Kill -9.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_9_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_10_5">
            Handicap Kill -10.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_10_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_11_5">
            Handicap Kill -11.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_11_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_12_5">
            Handicap Kill -12.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_12_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_negativo_13_5">
            Handicap Kill -13.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_negativo_13_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_3_5">
            Handicap Kill +3.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_3_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_4_5">
            Handicap Kill +4.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_4_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_5_5">
            Handicap Kill +5.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_5_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_6_5">
            Handicap Kill +6.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_6_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_7_5">
            Handicap Kill +7.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_7_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_8_5">
            Handicap Kill +8.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_8_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_9_5">
            Handicap Kill +9.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_9_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_10_5">
            Handicap Kill +10.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_10_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_11_5">
            Handicap Kill +11.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_11_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_12_5">
            Handicap Kill +12.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_12_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_kill_positivo_13_5">
            Handicap Kill +13.5:
            <k-progress
              :percent="resultado[clubea].handicap_kill_positivo_13_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_19_5_abaixo">
            Total Kills -19.5:
            <k-progress
              :percent="resultado[clubea].kill_19_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_20_5_abaixo">
            Total Kills -20.5:
            <k-progress
              :percent="resultado[clubea].kill_20_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_21_5_abaixo">
            Total Kills -21.5:
            <k-progress
              :percent="resultado[clubea].kill_21_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_22_5_abaixo">
            Total Kills -22.5:
            <k-progress
              :percent="resultado[clubea].kill_22_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_23_5_abaixo">
            Total Kills -23.5:
            <k-progress
              :percent="resultado[clubea].kill_23_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_24_5_abaixo">
            Total Kills -24.5:
            <k-progress
              :percent="resultado[clubea].kill_24_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_25_5_abaixo">
            Total Kills -25.5:
            <k-progress
              :percent="resultado[clubea].kill_25_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_26_5_abaixo">
            Total Kills -26.5:
            <k-progress
              :percent="resultado[clubea].kill_26_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_27_5_abaixo">
            Total Kills -27.5:
            <k-progress
              :percent="resultado[clubea].kill_27_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_28_5_abaixo">
            Total Kills -28.5:
            <k-progress
              :percent="resultado[clubea].kill_28_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_29_5_abaixo">
            Total Kills -29.5:
            <k-progress
              :percent="resultado[clubea].kill_29_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_19_5_acima">
            Total Kills +19.5:
            <k-progress
              :percent="resultado[clubea].kill_19_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_20_5_acima">
            Total Kills +20.5:
            <k-progress
              :percent="resultado[clubea].kill_20_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_21_5_acima">
            Total Kills +21.5:
            <k-progress
              :percent="resultado[clubea].kill_21_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_22_5_acima">
            Total Kills +22.5:
            <k-progress
              :percent="resultado[clubea].kill_22_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_23_5_acima">
            Total Kills +23.5:
            <k-progress
              :percent="resultado[clubea].kill_23_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_24_5_acima">
            Total Kills +24.5:
            <k-progress
              :percent="resultado[clubea].kill_24_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_25_5_acima">
            Total Kills +25.5:
            <k-progress
              :percent="resultado[clubea].kill_25_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_26_5_acima">
            Total Kills +26.5:
            <k-progress
              :percent="resultado[clubea].kill_26_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_27_5_acima">
            Total Kills +27.5:
            <k-progress
              :percent="resultado[clubea].kill_27_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_28_5_acima">
            Total Kills +28.5:
            <k-progress
              :percent="resultado[clubea].kill_28_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].kill_29_5_acima">
            Total Kills +29.5:
            <k-progress
              :percent="resultado[clubea].kill_29_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_negativo_800_0">
            Handicap Gold -800:
            <k-progress
              :percent="resultado[clubea].handicap_gold_negativo_800_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_negativo_2000_0">
            Handicap Gold -2000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_negativo_2000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_negativo_4000_0">
            Handicap Gold -4000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_negativo_4000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_negativo_5000_0">
            Handicap Gold -5000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_negativo_5000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_negativo_8000_0">
            Handicap Gold -8000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_negativo_8000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_negativo_10000_0">
            Handicap Gold -10000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_negativo_10000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_negativo_12000_0">
            Handicap Gold -12000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_negativo_12000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_positivo_800_0">
            Handicap Gold +800:
            <k-progress
              :percent="resultado[clubea].handicap_gold_positivo_800_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_positivo_2000_0">
            Handicap Gold +2000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_positivo_2000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_positivo_4000_0">
            Handicap Gold +4000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_positivo_4000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_positivo_5000_0">
            Handicap Gold +5000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_positivo_5000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_positivo_8000_0">
            Handicap Gold +8000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_positivo_8000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_positivo_10000_0">
            Handicap Gold +10.000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_positivo_10000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].handicap_gold_positivo_12000_0">
            Handicap Gold +12.000:
            <k-progress
              :percent="resultado[clubea].handicap_gold_positivo_12000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_abaixo_100">
            Total Gold -100k:
            <k-progress
              :percent="resultado[clubea].gold_abaixo_100"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_abaixo_105">
            Total Gold -105k:
            <k-progress
              :percent="resultado[clubea].gold_abaixo_105"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_abaixo_108">
            Total Gold -108k:
            <k-progress
              :percent="resultado[clubea].gold_abaixo_108"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_abaixo_110">
            Total Gold -110k:
            <k-progress
              :percent="resultado[clubea].gold_abaixo_110"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_abaixo_118">
            Total Gold -118k:
            <k-progress
              :percent="resultado[clubea].gold_abaixo_118"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_abaixo_120">
            Total Gold -120k:
            <k-progress
              :percent="resultado[clubea].gold_abaixo_120"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_acima_100">
            Total Gold +100k:
            <k-progress
              :percent="resultado[clubea].gold_acima_100"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_acima_105">
            Total Gold +105k:
            <k-progress
              :percent="resultado[clubea].gold_acima_105"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_acima_108">
            Total Gold +108k:
            <k-progress
              :percent="resultado[clubea].gold_acima_108"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_acima_110">
            Total Gold +110k:
            <k-progress
              :percent="resultado[clubea].gold_acima_110"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_acima_118">
            Total Gold +118k:
            <k-progress
              :percent="resultado[clubea].gold_acima_118"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].gold_acima_120">
            Total Gold +120k:
            <k-progress
              :percent="resultado[clubea].gold_acima_120"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].under_time_30">
            Game Time -30 minutos:
            <k-progress
              :percent="resultado[clubea].under_time_30"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].under_time_31">
            Game Time -31 minutos:
            <k-progress
              :percent="resultado[clubea].under_time_31"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].under_time_32">
            Game Time -32 minutos:
            <k-progress
              :percent="resultado[clubea].under_time_32"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].under_time_33">
            Game Time -33 minutos:
            <k-progress
              :percent="resultado[clubea].under_time_33"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].under_time_34">
            Game Time -34 minutos:
            <k-progress
              :percent="resultado[clubea].under_time_34"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].over_time_30">
            Game Time +30 minutos:
            <k-progress
              :percent="resultado[clubea].over_time_30"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].over_time_31">
            Game Time +31 minutos:
            <k-progress
              :percent="resultado[clubea].over_time_31"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].over_time_32">
            Game Time +32 minutos:
            <k-progress
              :percent="resultado[clubea].over_time_32"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].over_time_33">
            Game Time +33 minutos:
            <k-progress
              :percent="resultado[clubea].over_time_33"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubea].over_time_34">
            Game Time +34 minutos:
            <k-progress
              :percent="resultado[clubea].over_time_34"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
        </div>
        <div class="col-md-6">
          <div v-if="resultado[clubeb].at_least_dragon">
            Mata 1 dragon por partida:
            <k-progress
              :percent="resultado[clubeb].at_least_dragon"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].baron_over">
            Baron +1.5:
            <k-progress
              :percent="resultado[clubeb].baron_over"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].baron_under">
            Baron -1.5:
            <k-progress
              :percent="resultado[clubeb].baron_under"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].porcent_fb">
            First Blood:
            <k-progress
              :percent="resultado[clubeb].porcent_fb"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].porcent_ft">
            First Tower:
            <k-progress
              :percent="resultado[clubeb].porcent_ft"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].tower_11_5_abaixo">
            Tower -11.5:
            <k-progress
              :percent="resultado[clubeb].tower_11_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].tower_11_5_acima">
            Tower +11.5:
            <k-progress
              :percent="resultado[clubeb].tower_11_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].tower_12_5_abaixo">
            Tower -12.5:
            <k-progress
              :percent="resultado[clubeb].tower_12_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].tower_12_5_acima">
            Tower +12.5:
            <k-progress
              :percent="resultado[clubeb].tower_12_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].total_dragon_abaixo_4_5">
            Dragon -4.5:
            <k-progress
              :percent="resultado[clubeb].total_dragon_abaixo_4_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].total_dragon_acima_4_5">
            Dragon +4.5:
            <k-progress
              :percent="resultado[clubeb].total_dragon_acima_4_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].total_dragon_abaixo_5_5">
            Dragon -5.5:
            <k-progress
              :percent="resultado[clubeb].total_dragon_abaixo_5_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].total_dragon_acima_5_5">
            Dragon +5.5:
            <k-progress
              :percent="resultado[clubeb].total_dragon_acima_5_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_3_5">
            Handicap Kill -3.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_3_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_4_5">
            Handicap Kill -4.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_4_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_5_5">
            Handicap Kill -5.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_5_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_6_5">
            Handicap Kill -6.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_6_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_7_5">
            Handicap Kill -7.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_7_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_8_5">
            Handicap Kill -8.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_8_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_9_5">
            Handicap Kill -9.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_9_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_10_5">
            Handicap Kill -10.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_10_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_11_5">
            Handicap Kill -11.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_11_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_12_5">
            Handicap Kill -12.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_12_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_negativo_13_5">
            Handicap Kill -13.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_negativo_13_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_3_5">
            Handicap Kill +3.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_3_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_4_5">
            Handicap Kill +4.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_4_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_5_5">
            Handicap Kill +5.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_5_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_6_5">
            Handicap Kill +6.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_6_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_7_5">
            Handicap Kill +7.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_7_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_8_5">
            Handicap Kill +8.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_8_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_9_5">
            Handicap Kill +9.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_9_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_10_5">
            Handicap Kill +10.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_10_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_11_5">
            Handicap Kill +11.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_11_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_12_5">
            Handicap Kill +12.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_12_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_kill_positivo_13_5">
            Handicap Kill +13.5:
            <k-progress
              :percent="resultado[clubeb].handicap_kill_positivo_13_5"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_19_5_abaixo">
            Total Kills -19.5:
            <k-progress
              :percent="resultado[clubeb].kill_19_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_20_5_abaixo">
            Total Kills -20.5:
            <k-progress
              :percent="resultado[clubeb].kill_20_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_21_5_abaixo">
            Total Kills -21.5:
            <k-progress
              :percent="resultado[clubeb].kill_21_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_22_5_abaixo">
            Total Kills -22.5:
            <k-progress
              :percent="resultado[clubeb].kill_22_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_23_5_abaixo">
            Total Kills -23.5:
            <k-progress
              :percent="resultado[clubeb].kill_23_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_24_5_abaixo">
            Total Kills -24.5:
            <k-progress
              :percent="resultado[clubeb].kill_24_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_25_5_abaixo">
            Total Kills -25.5:
            <k-progress
              :percent="resultado[clubeb].kill_25_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_26_5_abaixo">
            Total Kills -26.5:
            <k-progress
              :percent="resultado[clubeb].kill_26_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_27_5_abaixo">
            Total Kills -27.5:
            <k-progress
              :percent="resultado[clubeb].kill_27_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_28_5_abaixo">
            Total Kills -28.5:
            <k-progress
              :percent="resultado[clubeb].kill_28_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_29_5_abaixo">
            Total Kills -29.5:
            <k-progress
              :percent="resultado[clubeb].kill_29_5_abaixo"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_19_5_acima">
            Total Kills +19.5:
            <k-progress
              :percent="resultado[clubeb].kill_19_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_20_5_acima">
            Total Kills +20.5:
            <k-progress
              :percent="resultado[clubeb].kill_20_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_21_5_acima">
            Total Kills +21.5:
            <k-progress
              :percent="resultado[clubeb].kill_21_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_22_5_acima">
            Total Kills +22.5:
            <k-progress
              :percent="resultado[clubeb].kill_22_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_23_5_acima">
            Total Kills +23.5:
            <k-progress
              :percent="resultado[clubeb].kill_23_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_24_5_acima">
            Total Kills +24.5:
            <k-progress
              :percent="resultado[clubeb].kill_24_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_25_5_acima">
            Total Kills +25.5:
            <k-progress
              :percent="resultado[clubeb].kill_25_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_26_5_acima">
            Total Kills +26.5:
            <k-progress
              :percent="resultado[clubeb].kill_26_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_27_5_acima">
            Total Kills +27.5:
            <k-progress
              :percent="resultado[clubeb].kill_27_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_28_5_acima">
            Total Kills +28.5:
            <k-progress
              :percent="resultado[clubeb].kill_28_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].kill_29_5_acima">
            Total Kills +29.5:
            <k-progress
              :percent="resultado[clubeb].kill_29_5_acima"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_negativo_800_0">
            Handicap Gold -800:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_negativo_800_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_negativo_2000_0">
            Handicap Gold -2000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_negativo_2000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_negativo_4000_0">
            Handicap Gold -4000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_negativo_4000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_negativo_5000_0">
            Handicap Gold -5000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_negativo_5000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_negativo_8000_0">
            Handicap Gold -8000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_negativo_8000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_negativo_10000_0">
            Handicap Gold -10000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_negativo_10000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_negativo_12000_0">
            Handicap Gold -12000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_negativo_12000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_positivo_800_0">
            Handicap Gold +800:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_positivo_800_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_positivo_2000_0">
            Handicap Gold +2000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_positivo_2000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_positivo_4000_0">
            Handicap Gold +4000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_positivo_4000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_positivo_5000_0">
            Handicap Gold +5000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_positivo_5000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_positivo_8000_0">
            Handicap Gold +8000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_positivo_8000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_positivo_10000_0">
            Handicap Gold +10.000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_positivo_10000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].handicap_gold_positivo_12000_0">
            Handicap Gold +12.000:
            <k-progress
              :percent="resultado[clubeb].handicap_gold_positivo_12000_0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_abaixo_100">
            Total Gold -100k:
            <k-progress
              :percent="resultado[clubeb].gold_abaixo_100"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_abaixo_105">
            Total Gold -105k:
            <k-progress
              :percent="resultado[clubeb].gold_abaixo_105"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_abaixo_108">
            Total Gold -108k:
            <k-progress
              :percent="resultado[clubeb].gold_abaixo_108"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_abaixo_110">
            Total Gold -110k:
            <k-progress
              :percent="resultado[clubeb].gold_abaixo_110"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_abaixo_118">
            Total Gold -118k:
            <k-progress
              :percent="resultado[clubeb].gold_abaixo_118"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_abaixo_120">
            Total Gold -120k:
            <k-progress
              :percent="resultado[clubeb].gold_abaixo_120"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_acima_100">
            Total Gold +100k:
            <k-progress
              :percent="resultado[clubeb].gold_acima_100"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_acima_105">
            Total Gold +105k:
            <k-progress
              :percent="resultado[clubeb].gold_acima_105"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_acima_108">
            Total Gold +108k:
            <k-progress
              :percent="resultado[clubeb].gold_acima_108"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_acima_110">
            Total Gold +110k:
            <k-progress
              :percent="resultado[clubeb].gold_acima_110"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_acima_118">
            Total Gold +118k:
            <k-progress
              :percent="resultado[clubeb].gold_acima_118"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].gold_acima_120">
            Total Gold +120k:
            <k-progress
              :percent="resultado[clubeb].gold_acima_120"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].under_time_30">
            Game Time -30 minutos:
            <k-progress
              :percent="resultado[clubeb].under_time_30"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].under_time_31">
            Game Time -31 minutos:
            <k-progress
              :percent="resultado[clubeb].under_time_31"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].under_time_32">
            Game Time -32 minutos:
            <k-progress
              :percent="resultado[clubeb].under_time_32"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].under_time_33">
            Game Time -33 minutos:
            <k-progress
              :percent="resultado[clubeb].under_time_33"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].under_time_34">
            Game Time -34 minutos:
            <k-progress
              :percent="resultado[clubeb].under_time_34"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].over_time_30">
            Game Time +30 minutos:
            <k-progress
              :percent="resultado[clubeb].over_time_30"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].over_time_31">
            Game Time +31 minutos:
            <k-progress
              :percent="resultado[clubeb].over_time_31"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].over_time_32">
            Game Time +32 minutos:
            <k-progress
              :percent="resultado[clubeb].over_time_32"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].over_time_33">
            Game Time +33 minutos:
            <k-progress
              :percent="resultado[clubeb].over_time_33"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
          <div v-if="resultado[clubeb].over_time_34">
            Game Time +34 minutos:
            <k-progress
              :percent="resultado[clubeb].over_time_34"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        resultado: Object,
        clubea: String,
        clubeb:String,
        tempoJogo: String,
        gold: String
    },
    data: () => {
        return {
            line_height: 12,
        }
    }
}
</script>

<style>
.k-progress-text {
  color: white;
}
</style>