import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MainLayout from '../views/MainLayout.vue'
import Balanco from '../views/Balanco.vue'
import Bot from '../views/Bot.vue'
import AnalisePartida from '../components/AnalisePartida.vue'
import AnalisePartidaBoost from '../components/AnalisePartidaBoost.vue'
import Live from '../views/Live.vue'

Vue.use(VueRouter)

const routes = [{
    path: '',
    name: 'Welcome',

    component: () => import('../views/Welcome.vue')
  },
  {
    path: '/',
    name: 'Index',
    component: MainLayout,
    children: [{
        path: '/bets',
        name: 'Home',
        component: Home
      },
      {
        path: '/live',
        name: 'Live',
        component: Live
      },
      {
        path: '/live-match/:idmatch',
        name: 'LiveMatch',
        component: () => import('../views/LiveMatch.vue'),
        props: true
      },
      {
        path: '/balancodiario',
        name: 'Balanco',
        component: Balanco
      },
      {
        path: '/about',
        name: 'About',
        component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: '/analise-partida',
        name: 'AnalisePartida',
        component: AnalisePartida,
        props: true
      },
      {
        path: '/analise-partida-boost',
        name: 'AnalisePartidaBoost',
        component: AnalisePartidaBoost
      },
      {
        path: '/profile',
        name: 'Profile',

        component: () => import('../views/Profile.vue')
      },
      {
        path: '/calculadora-odds',
        name: 'Calculadora',

        component: () => import('../components/Calculadora.vue')
      },
      {
        path: '/call-streamer',
        name: 'CallStreamer',

        component: () => import('../components/CallStreamer.vue')
      },
      {
        path: '/suporte',
        name: 'Suporte',

        component: () => import('../components/Suporte.vue')
      },
      {
        path: '/afiliado',
        name: 'Afiliado',

        component: () => import('../components/Afiliado.vue')
      },
      {
        path: '/contribua',
        name: 'Contribua',

        component: () => import('../components/Contribua.vue')
      },
    ]
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import( /* webpackChunkName: "about" */ '../views/Vip.vue')
  },
  {
    path: '/bot',
    name: 'Bot',
    component: Bot
  },
  {
    path: '/login',
    name: 'Login',

    component: () => import('../components/Login.vue')
  },
  {
    path: '/reset-password-send',
    name: 'ResetPasswordEmail',

    component: () => import('../components/ResetPassEmail.vue')
  },
  {
    path: '/change-password@:token',
    name: 'ResetPass',
    props: true,

    component: () => import('../components/ResetPass.vue')
  },
  {
    path: '/register',
    name: 'Register',

    component: () => import('../components/Register.vue'),
  },
  {
    path: '/register@:streamer',
    name: 'Apoiador',
    component: () => import('../components/RegisterApoiador.vue'),
    props: true,
  },
  {
    path: '/mundial',
    name: 'Mundial',

    component: () => import('../views/Mundial.vue')
  },
  {
    path: '/404',
    name: '404',

    component: () => import('../views/404.vue')
  },
  {
    path: '/manutencao',
    name: 'Manutencao',

    component: () => import('../views/Manutencao.vue')
  },
  {
    path: '/rivaliry',
    name: 'rivaliry',

    component: () => import('../views/Rivaliry.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router