<template>
  <div id="app">
    <div class="main-content">
      <!-- <Manutencao /> -->
      <router-view />
    </div>
  </div>
</template>

<script>
// import Login from './components/Login'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
// import Manutencao from './views/Manutencao'

export default {
  name: 'app',
  components: {
    // Manutencao
  },
  data: function () {
    return {
    }
  },
  mounted () {
    // this.token = this.$store.state.jwt
    const token = localStorage.getItem('token')
    if (token) {
      var test = VueJwtDecode.decode(token)
      //console.log(test.exp)
      var currentTime = new Date().getTime() / 1000
      //console.log(currentTime)
      if (currentTime > test.exp) {
        console.log('expirou')
        // this.$router.push({ name: 'Login' })
      } else {
        console.log('ainda esta valido')
        this.$store.commit('autenticar')        
        axios.post(this.$store.state.endpoints.refreshJWT, { token: token })
          .then((response) => {
            this.$store.state.jwt = response.data
            console.log('TOKEN ATUALIZADO COM SUCESSO')
            this.$store.commit('autenticar') 
          })
      }
    } else if (token === null) {
      console.log('NULO')
      // this.$router.push({ name: 'Login' })
    } else {
      // this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style>
/* #app { */
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
/* } */

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
