<template>
  <div class="table-responsive">
    <div v-if="!loadedList" class="d-flex justify-content-center">
      <atom-spinner :size="50" :color="'#00d97e'" />
    </div>
    <table class="table table-sm" v-else>
      <thead>
        <tr>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-row"
              >Data</a
            >
          </th>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-first"
              >Competição</a
            >
          </th>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-first"
              >Tipo</a
            >
          </th>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-last"
              >Palpite Vencedor</a
            >
          </th>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-handle"
              >Palpite perdedor</a
            >
          </th>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-handle"
              >Entrada</a
            >
          </th>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-handle"
              >ODD</a
            >
          </th>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-handle"
              >Resultado</a
            >
          </th>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-handle"
              >Lucro</a
            >
          </th>
          <th scope="col">
            <a href="#" class="text-muted list-sort" data-sort="tables-handle"
              >Ações</a
            >
          </th>
        </tr>
      </thead>
      <tbody class="list">
        <tr v-for="x in listBet" :key="x.id">
          <td scope="row" class="tables-row">{{ x.data }}</td>
          <td class="tables-first">{{ x.competicao }}</td>
          <td class="tables-first">{{ x.estilo }}</td>
          <td class="tables-last">{{ x.vencedor }}</td>
          <td class="tables-handle">{{ x.perdedor }}</td>
          <td class="tables-handle">{{ x.entrada }}</td>
          <td class="tables-handle">{{ x.odd }}</td>
          <div v-if="x.resultado === 'CERTO'">
            <td class="tables-handle">
              <span class="badge badge-soft-success mt-n1">CERTO</span>
            </td>
          </div>
          <div v-else-if="x.resultado === 'ERRADO'">
            <td class="tables-handle">
              <span class="badge badge-soft-danger mt-n1">ERRADO</span>
            </td>
          </div>
          <div v-else>
            <td class="tables-handle">
              <span class="badge badge badge-warning mt-n1">AGUARDANDO</span>
            </td>
          </div>
          <td class="tables-handle">{{ x.lucro }}</td>
          <td class="tables-handle">
            <button
              class="btn btn-sm btn-rounded-circle btn-white"
              @click="showComentario(x)"
              style="color: green"
            >
              <i class="fe fe-info"></i>
            </button>
            <button
              class="btn btn-sm btn-rounded-circle btn-white"
              @click="editBet(x)"
              style="color: orange"
            >
              <i class="fe fe-edit"></i>
            </button>
            <button
              class="btn btn-sm btn-rounded-circle btn-white"
              @click="deleteBet(x.id)"
              style="color: red; margin-left: 10px"
            >
              <i class="fe fe-x-circle"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <modal
      name="comentario-modal"
      styles="background-color: #1e3a5c;"
      height="250px"
      width="500px"
    >
      <div class="container-fluid">
        <div
          class="row"
          style="margin-bottom: 30px; margin-top: 20px; margin-left: 8px"
        >
          {{ comentarioModal }}
        </div>
      </div>
    </modal>
    <modal
      name="my-first-modal"
      styles="background-color: #1e3a5c;"
      height="550px"
      width="800px"
    >
      <div class="container-fluid">
        <div
          class="row"
          style="margin-bottom: 30px; margin-top: 20px; margin-left: 8px"
        >
          <div class="col-4">
            <span>Data</span>
            <flat-pickr
              v-model="dateBet"
              class="custom-select"
              placeholder="Data"
              :config="dataConfig"
            >
            </flat-pickr>
          </div>
          <div class="col-4">
            <span>Competição</span>
            <select class="custom-select" v-model="competicao">
              <option v-for="x in competicoes" :key="x.id">{{ x.name }}</option>
            </select>
          </div>
          <div class="col-4">
            <span>Estilo</span>
            <select class="custom-select" v-model="tipoBet">
              <option v-for="x in tiposBet" :key="x.id">{{ x.name }}</option>
            </select>
          </div>
        </div>
        <div
          class="row"
          style="margin-bottom: 30px; margin-top: 20px; margin-left: 15px"
        >
          <div class="col-4">
            <span>Vencedor</span>
            <v-select
              class="style-chooser"
              v-model="vencedor"
              :options="clubes"
            ></v-select>
          </div>
          <div class="col-4">
            <span>Perdedor</span>
            <v-select
              class="style-chooser"
              v-model="perdedor"
              :options="clubes"
            ></v-select>
          </div>
          <div class="col-4">
            <span>Timer que aposta foi feita</span>
            <the-mask
              v-model="tempoJogo"
              class="form-control"
              :mask="['##:##']"
            />
          </div>
        </div>
        <div
          class="row"
          style="margin-bottom: 30px; margin-top: 20px; margin-left: 15px"
        >
          <div class="col-5">
            <span>Entrada</span>
            <input
              type="text"
              class="custom-select"
              v-model="entrada"
              @input="calculaLucro"
            />
          </div>
          <div class="col-5">
            <span>ODD</span>
            <input
              type="text"
              class="custom-select"
              v-model="odd"
              @input="calculaLucro"
            />
          </div>
        </div>
        <div
          class="row"
          style="margin-bottom: 30px; margin-top: 20px; margin-left: 15px"
        >
          <div class="col-5">
            <span>Resultado</span>
            <select
              class="custom-select"
              v-model="resultadoBet"
              @change="calculaLucro"
            >
              <option>AGUARDANDO</option>
              <option>CERTO</option>
              <option>ERRADO</option>
            </select>
          </div>
          <div class="col-5">
            <span>Lucro</span>
            <input
              disabled
              type="text"
              class="form-control"
              v-model="lucro"
              style="border: 1px solid #12263f"
            />
          </div>
        </div>
        <div class="row" style="margin-left: 15px">
          <div class="col-9">
            <span>Comentários</span>
            <input
              v-model="comentarioModal"
              type="text"
              class="custom-select"
              style="heigth: 60px"
            />
          </div>
        </div>
        <div
          class="row"
          style="margin-bottom: 30px; margin-top: 20px; margin-left: 15px"
        >
          <div class="col-2">
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              @click="submitEditBet"
            >
              Salvar!
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {Portuguese} from 'flatpickr/dist/l10n/pt.js'
import {AtomSpinner} from 'epic-spinners'

export default {
  components: {
    flatPickr,
    vSelect,
    AtomSpinner
  },
  data: function () {
    return {
      competicao: {},
      tiposBet: {},
      tipoBet: {},
      vencedor: '',
      perdedor: '',
      entrada: '',
      odd: '',
      lucro: '',
      dateBet: '',
      clubes: '',
      competicoes: '',
      resultadoBet: '',
      id: 0,
      dataConfig: {
        locale: Portuguese
      },
      loadedList: false,
      comentarioModal: '',
      tempoJogo: ''
    }
  },
  mounted () {
    const config = {
            headers: {
        // Set your Authorization to 'JWT', not Bearer!!!
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }
    axios.get('list_bets', config)
    .then(resp => {
      this.$store.state.listBet = resp.data
      this.loadedList = true
    })
    .catch(err => {
      console.log(err)
    })
    axios.get('list_addBet')
    .then(resp => {
      this.competicoes = resp.data.competicoes
      this.tiposBet = resp.data.tiposBet
      var clubesArr = Object.values(resp.data.clubes)
      var result = clubesArr.map(x => (x.name))
      this.clubes = result
    })
    .catch(err => {
      console.log(err)
    })
  },
  computed: {
    ...mapState({
      listBet: state => state.listBet,
      counter: state => state.counter
    })
  },
  methods: {
    
    editBet: function (bet) {
      this.show()
      this.competicao = bet.competicao
      this.dateBet = bet.data
      this.entrada = bet.entrada
      this.lucro = bet.lucro
      this.odd = bet.odd
      this.perdedor = bet.perdedor
      this.resultadoBet = bet.resultado
      this.vencedor = bet.vencedor
      this.tipoBet = bet.estilo
      this.id = bet.id,
      this.comentarioModal = bet.comentario
      this.tempoJogo = bet.tempo_jogo
    },
    excluidBet: function(id) {
      console.log(id)
    },
  show: function () {
    this.$modal.show('my-first-modal');
    },
    hide: function () {
        this.$modal.hide('my-first-modal');
    },
  showModalComentario: function () {
    this.$modal.show('comentario-modal');
    },
    hideModalComentario: function () {
        this.$modal.hide('comentario-modal');
    },
    calculaLucro: function() {
      if (this.resultadoBet === 'CERTO') {
        var ganho = this.entrada * this.odd
        var lucro = ganho - this.entrada
        this.lucro = lucro.toFixed(2)
      } else if ( this.resultadoBet === 'ERRADO') {
        this.lucro = this.entrada * -1    
      } else {
        this.lucro = 0
      }
        },
    submitEditBet: function() {
    const config = {
            headers: {
        // Set your Authorization to 'JWT', not Bearer!!!
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }
    axios.post('edit_bet', {
      id: this.id,
      data: this.dateBet,
      competicao: this.competicao,
      estilo: this.tipoBet,
      vencedor: this.vencedor,
      perdedor: this.perdedor,
      entrada: this.entrada,
      odd: this.odd,
      resultado: this.resultadoBet,
      lucro: this.lucro,
      comentario: this.comentarioModal,
      tempoJogo: this.tempoJogo
    }, config)
    .then(resp => {
      this.$store.commit('attListBet',resp.data)
      this.$store.commit('addApostado', this.entrada)
      if (this.tipoBet === 'CERTO') {this.$store.commit('addLucro', this.lucro)}
      this.$notify.success('Bet alterada!')
      this.hide()
      axios.get('list_gasto_lucro', config)
      .then(resp => {
        var apostado = resp.data.apostado
        var lucro = resp.data.lucro
        var lucro_hoje = resp.data.lucro_hoje
        var lucro_mensal = resp.data.lucro_mensal

        this.$store.state.apostado = apostado
        this.$store.state.lucro = lucro
        this.$store.state.lucro_hoje = lucro_hoje
        this.$store.state.lucro_mensal = lucro_mensal
      })
    })
  },
  showComentario: function(id){
    this.comentarioModal = id.comentario
    this.showModalComentario()
  }
  ,
  deleteBet: function(id){
    const config = {
            headers: {
        // Set your Authorization to 'JWT', not Bearer!!!
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }
    axios.post('delete_bet', {
      id:id
    }, config)
    .then(resp => {
      this.$store.commit('attListBet',resp.data)
      this.$store.commit('addApostado', this.entrada)
      if (this.tipoBet === 'CERTO') {this.$store.commit('addLucro', this.lucro)}
      this.$notify.success('Bet excluida!!')
      this.hide()
      axios.get('list_gasto_lucro', config)
      .then(resp => {
        var apostado = resp.data.apostado
        var lucro = resp.data.lucro
        var lucro_hoje = resp.data.lucro_hoje
        var lucro_mensal = resp.data.lucro_mensal

        this.$store.state.apostado = apostado
        this.$store.state.lucro = lucro
        this.$store.state.lucro_hoje = lucro_hoje
        this.$store.state.lucro_mensal = lucro_mensal
      })
    })
  }
  },

}
</script>

<style>
vm--modal {
  background: #436186;
}
.style-chooser .vs__selected {
  color: white;
}
.style-chooser .vs__dropdown-toggle {
  background-color: #12263f;
  border: 1px solid #12263f;
  height: 40px;
}
.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: white;
}
.lucro {
  border: 1px solid #12263f;
}
</style>