<template>
  <div class="s">
        <!-- Novo aviso sobre contribuição -->
        <div class="alert alert-info alert-dismissible fade show" role="alert">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <strong>Ajude o BetLol a continuar gratuito!</strong>
          <p class="mb-0">
            Contribua via PIX para mantermos o site gratuito e com melhorias constantes.
            Toda ajuda é bem-vinda! 
          </p>
        </div>
        <div class="d-flex align-items-center">
          <router-link to="/contribua" class="btn btn-primary btn-sm mx-2">
            Quero Contribuir
          </router-link>
        </div>
      </div>
    </div>
    <div class="carregando" v-if="!isLoadingPage">
      <div class="d-flex justify-content-center">
        <div class="spinner title-center">
          <atom-spinner
            :animation-duration="1000"
            :size="80"
            :color="'#1dbbff'"
          />
          <h3 class="text-muted" style="margin-top: 10px">
            Carregando dados...
          </h3>
        </div>
      </div>
    </div>
    <div class v-else>
      <modal
        name="my-first-modal"
        styles="background-color: #1e3a5c;"
        height="700px"
        width="800px"
      >
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">
                <a href="#" class="text-muted list-sort" data-sort="tables-row"
                  >Picks</a
                >
              </th>
              <template class >
                <th scope="col" :key="i" v-for="(i, x) in champions[1]">
                  <a
                    href="#"
                    class="text-muted list-sort"
                    data-sort="tables-first"
                    v-if="x <= 7"
                    >Champ</a
                  >
                </th>
              </template>
            </tr>
          </thead>
          <tbody class="list">
            <tr v-for="(key, value) in champions" :key="value">
              <td
                scope="row"
                class="tables-row"
                v-if="champions[value].length > 0"
              >
                {{ value }}
              </td>
              <td scope="row" class="tables-row" v-for="(i, x) in key" :key="i">
                <span v-if="x <= 7">{{ i }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </modal>
      <div class="card">
        <div class="card-body text-center">
          <div class="d-flex flex-wrap justify-content-center">
            <div class="p-2">
              <div class="text-muted" style="margin-bottom: 30px">
                Escolha os patches para analise:
              </div>
              <div class="row">
                <div v-if="listPatchResp && listPatchResp.length > 0" class="patches-container">
                  <div
                    class="form-check patch-item"
                    v-for="i in listPatchResp"
                    :key="'patch_' + i"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="'patch_' + i"
                      :value="i"
                      @click="checkPatch($event)"
                      :checked="isChecked(i)"
                    />
                    <label class="form-check-label" :for="'patch_' + i">{{ i }}</label>
                  </div>
                </div>
                <div v-else class="text-muted">
                  Carregando patches...
                </div>
              </div>
              <!-- <v-select
                multiple
                class="style-chooser"
                v-model="listPatch"
                :options="listPatchResp"
              ></v-select> -->
            </div>
          </div>

          <div class="text-muted" style="margin-bottom: 30px; margin-top: 30px">
            Escolha os dois times que irão se enfrentar!
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- <a href="" class="card-avatar avatar avatar-lg mx-auto">
                    <img src="../assets/img/avatars/teams/team-logo-1.jpg" class="avatar-img rounded" >
              </a>-->
              <v-select
                class="style-chooser"
                v-model="clubea"
                :options="clubes"
              ></v-select>
            </div>
            <div class="col-md-6">
              <!-- <a href="" class="card-avatar avatar avatar-lg mx-auto">
                    <img src="../assets/img/avatars/teams/team-logo-1.jpg" class="avatar-img rounded" >
              </a>-->
              <v-select
                class="style-chooser"
                v-model="clubeb"
                :options="clubes"
              ></v-select>
            </div>
          </div>
          <div class="d-flex justify-content-center flex-wrap">
            <div class="p-2 flex-fill">
              <span>Total Torres</span>
              <input
                type="text"
                class="form-control"
                v-model="tower"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="p-2">
              <span>Handicap Kills</span>
              <input
                type="text"
                class="form-control"
                v-model="handicapKills"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="p-2">
              <span>Total Kills</span>
              <input
                type="text"
                class="form-control"
                v-model="totalKills"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="p-2">
              <span>Total Gold</span>
              <input
                type="text"
                class="form-control"
                v-model="gold"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="p-2">
              <span>Under/Over Kill</span>
              <input
                type="text"
                class="form-control"
                v-model="kills_times"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="p-2">
              <span>Handicap Gold</span>
              <input
                type="text"
                class="form-control"
                v-model="handicapGold"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="p-2">
              <span>Tempo de Jogo</span>
              <input
                type="text"
                class="form-control"
                v-model="tempoJogo"
                v-on:keypress="isNumber($event)"
              />
            </div>
            <div class="p-2">
              <span>Dragons</span>
              <input
                type="text"
                class="form-control"
                v-model="get_dragons"
                v-on:keypress="isNumber($event)"
              />
            </div>
          </div>
          <!-- <div class="col-md-2 cust-grid-col">
          
          </div>-->
          <div v-if="isLoading" class="title-center" style="margin-top: 30px">
            <atom-spinner :size="50" :color="'#00d97e'" />
          </div>
          <button
            v-else
            type="button"
            id="btn-add-bet"
            class="btn btn-outline-success mb-2"
            style="margin-top: 30px"
            @click="submitAnalise"
          >
            Go!
          </button>
          <div class="mb-2" v-if="isLoaded">
            <!-- <button type="button" id=btn-add-bet class="btn btn-outline-success mb-2" style="margin-top: 30px; margin-right: 10px;" @click="showGeral">Geral</button>
            <button type="button" id=btn-add-bet class="btn btn-outline-success mb-2" style="margin-top: 30px" @click="showHeadToHead">Confronto direto</button>-->
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showFirstBlood"
            >
              First Blood
            </button>
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showFirstTower"
            >
              First Tower
            </button>
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showFirstHerald"
            >
              First Herald
            </button>
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showFirstBaron"
            >
              First Baron
            </button>
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showFirstDrake"
            >
              First Drake
            </button>
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showTotalKills"
            >
              Total Kills
            </button>
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showGameTime"
            >
              Game Time
            </button>
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showSimplificada"
            >
              Simplificada
            </button>
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showDrakeType"
            >
              Tipos Drake
            </button>
            <button
              type="button"
              id="btn-add-bet"
              class="btn btn-outline-success mb-2"
              style="margin-top: 30px; margin-right: 8px"
              @click="showPicaFunc"
            >
              Picks/Bans
            </button>
          </div>
        </div>
        <FirstBlood
          v-if="showfb"
          :dados="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
          :confronto="haveConfronto"
        />
        <FirstTower
          v-if="showft"
          :dados="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
          :confronto="haveConfronto"
        />
        <FirstHerald
          v-if="showHerald"
          :dados="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
          :confronto="haveConfronto"
        />
        <FirstBaron
          v-if="showfbaron"
          :dados="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
          :confronto="haveConfronto"
        />
        <FirstDrake
          v-if="showfd"
          :dados="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
          :confronto="haveConfronto"
        />
        <TotalKills
          v-if="showtotalkills"
          :dados="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
          :confronto="haveConfronto"
        />
        <GameTime
          v-if="gameTime"
          :dados="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
          :time="tempoJogo"
          :confronto="haveConfronto"
        />
        <Simplificada
          v-if="showSimpli"
          :resultado="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
          :gold="gold"
          :tempoJogo="tempoJogo"
        />
        <DrakeType
          v-if="drakeType"
          :dados="resultado"
          :clubea="clubea"
          :clubeb="clubeb"
        />
        <Picks
          v-if="showPicks"
          :dados="resultado.picks"
          :bans="resultado.bans"
        />
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import axios from 'axios'
import FirstBlood from '@/components/FirstBlood.vue'
import FirstTower from '@/components/FirstTower.vue'
import FirstDrake from '@/components/FirstDrake.vue'
import FirstBaron from '@/components/FirstBaron.vue'
import TotalKills from '@/components/TotalKills.vue'
import Picks from '@/components/Picks.vue'
import Simplificada from '@/components/AnaliseSimplificada.vue'
import GameTime from '@/components/GameTime.vue'
import FirstHerald from '@/components/FirstHerald.vue'
import DrakeType from '@/components/DrakeType.vue'
import {AtomSpinner} from 'epic-spinners'

export default {
    components: {
        vSelect,
        FirstBlood,
        FirstTower,
        FirstDrake,
        FirstBaron,
        TotalKills,
        Simplificada,
        GameTime,
        FirstHerald,
        AtomSpinner,
        DrakeType,
        Picks
    },
    props: {
      clubea: String,
      clubeb: String,
    },
    data: function () {
        return {
            resultado: [],
            headToHead: [],
            clubes: [],
            showResultado: false,
            tower: 11.5,
            handicapKills: 4.5,
            totalKills: 29.5,
            gold: 120,
            havePermission: true,
            kills_times: 12,
            handicapGold: 800,
            tempoJogo: '30',
            listPatch: [],
            listPatchResp: [],
            patch: '',
            champions: {},
            showHeadtohead: false,
            get_dragons: 4.5,
            showfb: false,
            showft: false,
            showfd: false,
            showfbaron: false,
            showtotalkills: false,
            showSimpli: false,
            showHerald: false,
            gameTime: false,
            drakeType: false,
            isLoaded: false,
            isLoading: false,
            isLoadingPage: false,
            haveConfronto: '',
            showPicks: false
        }
    },
    async mounted() {
      try {
        console.log('Iniciando mounted...') // Debug

        const config = {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
        }

        // Verifica token
        if (!localStorage.getItem('token')) {
          console.log('Token não encontrado')
          this.isLoadingPage = true
          return
        }

        // Verifica status do perfil
        // const statusResp = await axios.get('check_status_perfil', config)
        // console.log('Status perfil:', statusResp.data) // Debug

        this.havePermission = true

        // Carrega clubes
        try {
          const clubesResp = await axios.get('list_clube_stats', config)
          this.clubes = clubesResp.data
          console.log('Clubes carregados') // Debug
        } catch (error) {
          console.error('Erro ao carregar clubes:', error)
        }

        // Carrega patches
        try {
          const patchesResp = await axios.get('list_patch_analise_partida', config)
          const patches_local = JSON.parse(localStorage.getItem('list_patch') || '[]')
          this.listPatch = patches_local
          this.listPatchResp = patchesResp.data
          console.log('Patches carregados:', this.listPatchResp) // Debug
        } catch (error) {
          console.error('Erro ao carregar patches:', error)
        }
      } catch (error) {
        console.error('Erro no mounted:', error)
        if (error.response) {
          console.error('Resposta do servidor:', error.response.data)
        }
      } finally {
        this.isLoadingPage = true
        console.log('Mounted finalizado') // Debug
      }
    },
    methods: {        
      submitAnalise: function () {
          this.resultado = {}
          this.reloadComponents()
          this.showHeadtohead = false
          this.isLoading = true
          if (this.listPatch.length === 0) {
            this.$notify('Escolha um patch para analise!', 'error')
            this.isLoading = false
          }
          else {
            const config = {
              headers: {
                // Set your Authorization to 'JWT', not Bearer!!!
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
            }   
              this.resultado = []
              axios.get(`stats/${this.clubea}/${this.clubeb}/${this.tower}/${this.gold}/${this.totalKills}/${this.handicapKills}/${this.kills_times}/${this.handicapGold}/${this.tempoJogo}/${this.listPatch}/${this.get_dragons}`, config)
                .then(resp => {
                    this.showResultado = true
                    this.resultado = resp.data
                     this.isLoaded = true
                     this.showSimpli = true
                     console.log(this.resultado)
                     this.isLoading = false
                     if (resp.data.head_to_head == 'não existe confronto direto') {
                       this.haveConfronto = false
                     } else {
                       this.haveConfronto = true
                     }
                     localStorage.setItem('list_patch',JSON.stringify(this.listPatch))
              })
              .catch(err => {             
              this.$notify(err.response.data, 'error')
              this.$notify('Caso não tenha o time desejado, contate nosso suporte!', 'error')
              this.isLoading = false
              })
            }
        },
    isNumber: function (evt) {
      evt = (evt) || window.event
      var charCode = (evt.wich) ? evt.wich : evt.keyCode
      if (charCode === 44 || charCode === 45 || charCode === 47) {
        evt.preventDefault()
        this.$notify('Use apenas ponto nos valores!', 'error')
      } else {
        return true
      }
    },
    show: function () {
      this.$modal.show('my-first-modal');
    },
    hide: function () {
        this.$modal.hide('my-first-modal');
    },
    showPicaFunc: function () {
      this.showPicks = !this.showPicks
      this.showft = false
      this.showfbaron = false
      this.showfd = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
    },
    showGeral: function () {
      this.showHeadtohead = false
    },
    showHeadToHead: function () {
      console.log(Object.keys(this.resultado.head_to_head).length)
      if (Object.keys(this.resultado.head_to_head).length === 0) {
        this.$notify('Não encontramos confrontos diretos para os times!', 'error')
      }
      this.showHeadtohead = true
    },
    showFirstBlood: function () {
      this.showfb = !this.showfb
      this.showft = false
      this.showfbaron = false
      this.showfd = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showFirstTower: function () {
      this.showft = !this.showft
      this.showfb = false
      this.showfbaron = false
      this.showfd = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showFirstBaron: function () {
      this.showfbaron = !this.showfbaron
      this.showfb = false
      this.showft = false
      this.showfd = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showFirstDrake: function () {
      this.showfd = !this.showfd
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showTotalKills: function () {
      this.showtotalkills = !this.showtotalkills
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.gameTime = false
      this.showSimpli = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showSimplificada: function () {
      this.showSimpli = !this.showSimpli
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.gameTime = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showGameTime: function () {
      this.gameTime = !this.gameTime
      this.showSimpli = false
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.drakeType = false
      this.showHerald = false
      this.showPicks = false
    },
    showFirstHerald: function () {
      this.showHerald = !this.showHerald
      this.showSimpli = false
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.showgameTime = false
      this.drakeType = false
      this.showPicks = false
    },
    showDrakeType: function () {
      this.drakeType = !this.drakeType
      this.showHerald = false
      this.showSimpli = false
      this.showfd = false
      this.showfb = false
      this.showft = false
      this.showfbaron = false
      this.showtotalkills = false
      this.showgameTime = false
      this.showPicks = false
    },
    reloadComponents: function() {
      this.showfb = false
      this.showft = false
      this.showfd = false
      this.showfbaron = false
      this.showtotalkills = false
      this.showSimpli = false
      this.gameTime = false
      this.showHerald = false
      this.drakeType = false
      this.showPicks = false
    },
    checkPatch: function (e) {
      var is_checked = e.target.checked
      var value = e.target.defaultValue
      if (is_checked == true) {
        this.listPatch.push(value)
      } else {
        var index = this.listPatch.indexOf(value);
        if (index !== -1) {
          this.listPatch.splice(index, 1);
        }
      }
    },
    isChecked: function (value) {
      return this.listPatch.includes(value)
    }
    },
}
</script>

<style>
.rola {
  display: flex;
  flex-wrap: wrap;
}
.cust-grid {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 15px;
}
.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-item-1 {
  flex: 1;
}

.cust-grid-col {
  float: none;
}
.vs__selected {
  background-color: #49688e;
}
.title-center {
  display: grid;
  place-items: center;
}
.carregando {
  padding-top: 5cm;
}

.patches-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  padding: 10px;
}

.patch-item {
  display: flex;
  align-items: center;
  margin: 0 !important; /* Remove margem padrão */
  min-width: 80px;
}

.form-check-input {
  margin-right: 5px;
}
</style>