<template>
  <div class="card-footer card-footer-boxed">
    <div class="card-footer">
      <div class="row">
        <div class="col-md-6">
          <h2 class="title-center">Geral</h2>
        </div>
        <div class="col-md-6">
          <h2 class="title-center">Confontro Direto</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <i class="fe fe-trending-down title-center"> </i>
          <h2 class="title-center">Under Time</h2>

          <h2 class="title-center">{{ time }} min</h2>
        </div>
        <div class="col-md-6">
          <i class="fe fe-trending-down title-center"> </i>
          <h2 class="title-center">Under Time</h2>
          <h2 class="title-center">{{ time }} min</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5 class="title-center text-muted">{{ clubea }}</h5>
        </div>
        <div class="col">
          <h5 class="title-center text-muted">{{ clubeb }}</h5>
        </div>
        <div class="col">
          <h5 class="title-center text-muted">{{ clubea }}</h5>
        </div>
        <div class="col">
          <h5 class="title-center text-muted">{{ clubeb }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col title-center">
          Jogos:{{ dados[clubea].partidas_jogadas }}
        </div>
        <div class="col title-center">
          Jogos: {{ dados[clubeb].partidas_jogadas }}
        </div>
        <template v-if="confronto">
          <div class="col title-center">
            Jogos: {{ dados.head_to_head[clubea].partidas_jogadas }}
          </div>
          <div class="col title-center">
            Jogos: {{ dados.head_to_head[clubeb].partidas_jogadas }}
          </div>
        </template>
        <template v-else>
          <div class="col title-center">Jogos: 0</div>
          <div class="col title-center">Jogos: 0</div>
        </template>
      </div>
      <div class="row">
        <div class="col">
          <k-progress
            :percent="dados[clubea].under_time"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          <k-progress
            :percent="dados[clubeb].under_time"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <template v-if="confronto">
          <div class="col">
            <k-progress
              :percent="dados.head_to_head[clubea].under_time"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
          <div class="col">
            <k-progress
              :percent="dados.head_to_head[clubeb].under_time"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
        </template>
        <template v-else>
          <div class="col">
            <k-progress
              percent="0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
          <div class="col">
            <k-progress
              percent="0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
        </template>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <i class="fe fe-trending-up title-center"> </i>
          <h2 class="title-center">Over Time</h2>
          <h2 class="title-center">{{ time }} min</h2>
        </div>
        <div class="col-md-6">
          <i class="fe fe-trending-up title-center"> </i>
          <h2 class="title-center">Over Time</h2>
          <h2 class="title-center">{{ time }} min</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <k-progress
            :percent="dados[clubea].over_time"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          <k-progress
            :percent="dados[clubeb].over_time"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <template v-if="confronto">
          <div class="col">
            <k-progress
              :percent="dados.head_to_head[clubea].over_time"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
          <div class="col">
            <k-progress
              :percent="dados.head_to_head[clubeb].over_time"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
        </template>
        <template v-else>
          <div class="col">
            <k-progress
              percent="0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
          <div class="col">
            <k-progress
              percent="0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
        </template>
      </div>
      <div class="row">
        <div class="col">
          <div class="row" v-for="p in dados.hist_partidas[clubea]" :key="p">
            <div class="col">
              {{ p.time1_slug }}
            </div>
            <div class="col-md-auto">
              <span>{{ p.tempo_partida.slice(3, 9) }}</span>
            </div>
            <div class="col">
              {{ p.time2_slug }}
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row" v-for="p in dados.hist_partidas[clubeb]" :key="p">
            <div class="col">
              {{ p.time1_slug }}
            </div>
            <div class="col-md-auto">
              <span>{{ p.tempo_partida.slice(3, 9) }}</span>
            </div>
            <div class="col">
              {{ p.time2_slug }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div
            class="col title-center"
            v-for="p in dados.hist_partidas_head"
            :key="p"
          >
            <div class="row head">
              <div class="col">
                {{ p.time1_slug }}
              </div>
              <div class="col-md-auto">
                <span>{{ p.tempo_partida.slice(3, 9) }}</span>
              </div>
              <div class="col">
                {{ p.time2_slug }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        dados: Object,
        clubea: String,
        clubeb:String,
        time: String,
        confronto: Boolean
    },
    data: () => {
        return {
            line_height: 12,
        }
    },
    methods: {
        redGreen: function(time,fb) {
           return {
               'versusred' : time != fb,
               'versusgreen' : time == fb
           }
        },
        checkConfronto: function(){
          return this.confronto
        }
    }
}
</script>

<style>
</style>