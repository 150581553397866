<template>
  <div class="card-footer card-footer-boxed">
    <div class="card-footer">
      <div class="row">
        <div class="col-md-6">
          <h2 class="title-center">Geral</h2>
        </div>
        <div class="col-md-6">
          <h2 class="title-center">Confontro Direto</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5 class="title-center text-muted">{{ clubea }}</h5>
        </div>
        <div class="col">
          <h5 class="title-center text-muted">{{ clubeb }}</h5>
        </div>
        <div class="col">
          <h5 class="title-center text-muted">{{ clubea }}</h5>
        </div>
        <div class="col">
          <h5 class="title-center text-muted">{{ clubeb }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col title-center">
          Jogos:{{ dados[clubea].partidas_jogadas }}
        </div>
        <div class="col title-center">
          Jogos: {{ dados[clubeb].partidas_jogadas }}
        </div>
        <template v-if="confronto">
          <div class="col title-center">
            Jogos: {{ dados.head_to_head[clubea].partidas_jogadas }}
          </div>
          <div class="col title-center">
            Jogos: {{ dados.head_to_head[clubeb].partidas_jogadas }}
          </div>
        </template>
        <template v-else>
          <div class="col title-center">Jogos: 0</div>
          <div class="col title-center">Jogos: 0</div>
        </template>
      </div>
      <div class="row">
        <div class="col title-center">First Blood: {{ dados[clubea].fb }}</div>
        <div class="col title-center">First Blood: {{ dados[clubeb].fb }}</div>
        <template v-if="confronto">
          <div class="col title-center">
            First Blood: {{ dados.head_to_head[clubea].fb }}
          </div>
          <div class="col title-center">
            First Blood: {{ dados.head_to_head[clubeb].fb }}
          </div>
        </template>
        <template v-else>
          <div class="col title-center">First Blood: 0</div>
          <div class="col title-center">First Blood: 0</div>
        </template>
      </div>
      <div class="row">
        <div class="col">
          <k-progress
            :percent="dados[clubea].porcent_fb"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          <k-progress
            :percent="dados[clubeb].porcent_fb"
            type="line"
            bg-color="#ff4040"
            color="#458b00"
            :line-height="line_height"
          />
        </div>
        <template v-if="confronto">
          <div class="col">
            <k-progress
              :percent="dados.head_to_head[clubea].porcent_fb"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
          <div class="col">
            <k-progress
              :percent="dados.head_to_head[clubeb].porcent_fb"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
        </template>
        <template v-else>
          <div class="col">
            <k-progress
              percent="0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
          <div class="col">
            <k-progress
              percent="0"
              type="line"
              bg-color="#ff4040"
              color="#458b00"
              :line-height="line_height"
            />
          </div>
        </template>
      </div>
      <div class="row">
        <div class="col">
          Blue side - {{ dados[clubea].fb_blue_side_qtd }}
          <k-progress
            :percent="dados[clubea].fb_blue_side"
            type="line"
            bg-color="#fff"
            color="#1e38f9"
            :line-height="line_height"
          />
          Red side- {{ dados[clubea].fb_red_side_qtd }}
          <k-progress
            :percent="dados[clubea].fb_red_side"
            type="line"
            bg-color="#fff"
            color="#ff4545"
            :line-height="line_height"
          />
        </div>
        <div class="col">
          Blue side - {{ dados[clubeb].fb_blue_side_qtd }}
          <k-progress
            :percent="dados[clubeb].fb_blue_side"
            type="line"
            bg-color="#fff"
            color="#1e38f9"
            :line-height="line_height"
          />
          Red side - {{ dados[clubeb].fb_red_side_qtd }}
          <k-progress
            :percent="dados[clubeb].fb_red_side"
            type="line"
            bg-color="#fff"
            color="#ff4545"
            :line-height="line_height"
          />
        </div>
        <template v-if="confronto">
          <div class="col">
            Blue side
            <k-progress
              :percent="dados.head_to_head[clubea].fb_blue_side"
              type="line"
              bg-color="#fff"
              color="#1e38f9"
              :line-height="line_height"
            />
            Red side
            <k-progress
              :percent="dados.head_to_head[clubea].fb_red_side"
              type="line"
              bg-color="#fff"
              color="#ff4545"
              :line-height="line_height"
            />
          </div>
          <div class="col">
            Blue side
            <k-progress
              :percent="dados.head_to_head[clubeb].fb_blue_side"
              type="line"
              bg-color="#fff"
              color="#1e38f9"
              :line-height="line_height"
            />
            Red side
            <k-progress
              :percent="dados.head_to_head[clubeb].fb_red_side"
              type="line"
              bg-color="#fff"
              color="#ff4545"
              :line-height="line_height"
            />
          </div>
        </template>
        <template v-else>
          <div class="col">
            Blue side
            <k-progress
              percent="0"
              type="line"
              bg-color="#fff"
              color="#1e38f9"
              :line-height="line_height"
            />
            Red side
            <k-progress
              percent="0"
              type="line"
              bg-color="#fff"
              color="#ff4545"
              :line-height="line_height"
            />
          </div>
          <div class="col">
            Blue side
            <k-progress
              percent="0"
              type="line"
              bg-color="#fff"
              color="#1e38f9"
              :line-height="line_height"
            />
            Red side
            <k-progress
              percent="0"
              type="line"
              bg-color="#fff"
              color="#ff4545"
              :line-height="line_height"
            />
          </div>
        </template>
      </div>
      <div class="row d-flex">
        <div class="col">
          <div class="row" v-for="p in dados.hist_partidas[clubea]" :key="p">
            <div class="col">
              {{ p.time1_slug }}
            </div>
            <div class="col-md-auto">
              <span :class="redGreen(p.time1, p.fb)">X</span>
              <span :class="redGreen(p.time2, p.fb)">X</span>
            </div>
            <div class="col">
              {{ p.time2_slug }}
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row" v-for="p in dados.hist_partidas[clubeb]" :key="p">
            <div class="col">
              {{ p.time1_slug }}
            </div>
            <div class="col-md-auto">
              <span :class="redGreen(p.time1, p.fb)">X</span>
              <span :class="redGreen(p.time2, p.fb)">X</span>
            </div>
            <div class="col">
              {{ p.time2_slug }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div
            class="col title-center"
            v-for="p in dados.hist_partidas_head"
            :key="p"
          >
            <div class="row head">
              <div class="col">
                {{ p.time1_slug }}
              </div>
              <div class="col-md-auto">
                <span :class="redGreen(p.time1, p.fb)">X</span>
                <span :class="redGreen(p.time2, p.fb)">X</span>
              </div>
              <div class="col">
                {{ p.time2_slug }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        dados: Object,
        clubea: String,
        clubeb:String,
        confronto: Boolean
    },
    mounted (){
      console.log(this.confronto)
    },
    data: () => {
        return {
            line_height: 12,
        }
    },
    methods: {
        redGreen: function(time,fb) {
           return {
               'versusred' : time != fb,
               'versusgreen' : time == fb
           }
        },
        checkConfronto: function(){
          return this.confronto
        }
    }
}
</script>

<style>
.versusred {
  color: red;
  margin-left: 5px;
  margin-right: 8px;
}
.versusgreen {
  color: green;
  margin-left: 5px;
  margin-right: 8px;
}
.versus {
  max-width: 30%;
  max-height: 50%;
}
.head {
  width: 60%;
}
</style>