<template>
<div class="j">
  <div class="flex-row">
    <button type="button" class="btn btn-outline-success mb-1" @click="expandLucroDiario" >Dirário</button>
    <button type="button" class="btn btn-outline-success mb-1" @click="expandLucroEstilo">Estilo de aposta</button>
    <button type="button" class="btn btn-outline-success mb-1" @click="expandLucroClubes" >Times</button>
  </div>
  <div class="" v-if="isLoading">
    <div class="d-flex justify-content-center">   
      <div class="spinner">
       <atom-spinner 
          :animation-duration="1000"
          :size="80"
          :color="'#1dbbff'"
     />
      </div>  
     </div>
  </div>
<div class="flex-row" v-else >
    <div class="flex-col">
  <div class="card" v-if="showLucroDiario">
  <div class="table-responsive">
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">
          <a class="text-muted" >Data</a>
        </th>
        <th scope="col">
          <a class="text-muted" >Lucro</a>
        </th>
      </tr>
    </thead>
    <tbody class="list">
      <tr v-for="x in dadosTabela" :key="x.id">
        <td scope="row" class="tables-row">{{x.data}}</td>
        <td class="tables-first">{{x.lucro}}</td>
      </tr>
    </tbody>
  </table>
  </div>
  </div>
  </div>
    <div class="flex-col">
  <div class="card" v-if="showLucroEstilo">
  <div class="table-responsive" data-list='{"valueNames": ["tables-row", "tables-first", "tables-last", "tables-handle"]}'>
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">
          <a href="#" class="text-muted" >Estilo</a>
        </th>
        <th scope="col">
          <a href="#" class="text-muted" >Lucro</a>
        </th>
        <th scope="col">
          <a href="#" class="text-muted" >Porcentagem</a>
        </th>
      </tr>
    </thead>
    <tbody class="list">
      <tr v-for="(key,value) in estiloSorted" :key="value">
        <td scope="row" class="tables-row">{{key[0]}}</td>
        <td class="tables-first" style="color: red;" v-if="key[1].lucro < 0">{{key[1].lucro}}</td>
        <td v-else class="tables-first" style="color: white;">{{key[1].lucro}}</td>
        <td class="tables-first" style="color: white;">{{key[1].porcentagem}} %</td>
      </tr>
    </tbody>
  </table>
  </div>
  </div>
  </div>
    <div class="flex-col">
  <div class="card" v-if="showLucroClubes">
  <div class="table-responsive" data-list='{"valueNames": ["tables-row", "tables-first", "tables-last", "tables-handle"]}'>
  <table class="table table-sm">
    <thead>
      <tr>
        <th scope="col">
          <a href="#" class="text-muted" >Estilo</a>
        </th>
        <th scope="col">
          <a href="#" class="text-muted" >Lucro</a>
        </th>
      </tr>
    </thead>
    <tbody class="list">
      <tr v-for="(key,value) in clubeSorted" :key="value">
        <td scope="row" class="tables-row">{{key[0]}}</td>
        <td class="tables-first" style="color: red;" v-if="key[1] < 0">{{key[1]}}</td>
        <td v-else class="tables-first" style="color: white;">{{key[1]}}</td>
      </tr>
    </tbody>
  </table>
  </div>
  </div>
  </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import {AtomSpinner} from 'epic-spinners'

export default {
  data () {
    return {
      dadosTabela: {},
      dadosEstiloBet: {},
      dadosClubesBet: {},
      estiloSorted: [],
      clubeSorted: [],
      btnLucroDiario: '-',
      btnLucroEstilo: '-',
      btnLucroClubes: '-',
      showLucroDiario: true,
      showLucroEstilo: false,
      showLucroClubes: false,
      isLoading: true
    }
  },
  components: {
    AtomSpinner
  },
  mounted(){
    const config = {
    headers: {
        // Set your Authorization to 'JWT', not Bearer!!!
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }
    axios.get('list_lucro_dia', config)
    .then(resp => {
      this.dadosTabela = resp.data
    })
    .catch(err => {
      console.log(err)
    })
    axios.get('list_estilo_bet', config)
    .then(resp => {
      this.dadosEstiloBet = resp.data
      this.estiloSort()
    })
    .catch(err => {
      console.log(err)
    })
    axios.get('list_clube_lucro', config)
    .then(resp => {
      this.dadosClubesBet = resp.data
      this.clubeSort()
      this.isLoading = false
    })
    .catch(err => {
      console.log(err)
    })
    
  },
  methods: {
      estiloSort: function(){
      var items = Object.keys(this.dadosEstiloBet).map(key => {
      return [key, this.dadosEstiloBet[key]]})   
      console.log(items)  
      items.sort(function(first,second){
        return second[1] - first[1]
      })  
      console.log(items)
      this.estiloSorted = items
    },
      clubeSort: function(){
      var items = Object.keys(this.dadosClubesBet).map(key => {
      return [key, this.dadosClubesBet[key]]})     
      items.sort(function(first,second){
        return second[1] - first[1]
      })  
      this.clubeSorted = items
    },
    expandLucroDiario: function(){
      this.showLucroClubes = false
      this.showLucroEstilo = false
      this.showLucroDiario = !this.showLucroDiario
      if (this.btnLucroDiario === '-') {this.btnLucroDiario = '+'}
      else { this.btnLucroDiario = '-'}
    },
    expandLucroEstilo: function(){
      this.showLucroDiario = false
      this.showLucroEstilo = false
      this.showLucroEstilo = !this.showLucroEstilo
      if (this.btnLucroEstilo === '-') {this.btnLucroEstilo = '+'}
      else { this.btnLucroEstilo = '-'}
    },
    expandLucroClubes: function(){
      this.showLucroDiario = false
      this.showLucroEstilo = false
      this.showLucroClubes = !this.showLucroClubes
      if (this.btnLucroClubes === '-') {this.btnLucroClubes = '+'}
      else { this.btnLucroClubes = '-'}
    },
  }

}
</script>

<style scoped>
.spinner {
  margin-top: 100px;
}
.btn {
  margin-right: 10px;
}

</style>