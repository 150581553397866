<template>
  <!-- CARDS -->
  <div class="container-fluid">
    <div class="row cust-grid">
      <div class="col-md-2 cust-grid-col">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">PORCENTAGEM</h6>

                <!-- Heading -->
                <div v-if="loadedLucro">
                  <span class="h2 mb-0"> {{ porcentagem }} </span>
                </div>
                <div v-else>
                  <atom-spinner
                    :animation-duration="1000"
                    :size="40"
                    :color="'#ffffff'"
                  />
                </div>

                <!-- Badge
                    <span class="badge badge-soft-success mt-n1">
                      +3.5%
                    </span> -->
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-percent text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-md-2 cust-grid-col">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">LUCRO TOTAL</h6>

                <!-- Heading -->
                <div v-if="loadedLucro">
                  <span class="h2 mb-0"> ${{ lucro }} </span>
                </div>
                <div v-else>
                  <atom-spinner
                    :animation-duration="1000"
                    :size="40"
                    :color="'#ffffff'"
                  />
                </div>

                <!-- Badge
                    <span class="badge badge-soft-success mt-n1">
                      +3.5%
                    </span> -->
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-dollar-sign text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>

      <div class="col-md-2 cust-grid-col">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">LUCRO HOJE</h6>

                <!-- Heading -->
                <div v-if="loadedLucro">
                  <span class="h2 mb-0"> ${{ lucro_hoje }} </span>
                </div>
                <div v-else>
                  <atom-spinner
                    :animation-duration="1000"
                    :size="40"
                    :color="'#ffffff'"
                  />
                </div>

                <!-- Badge
                    <span class="badge badge-soft-success mt-n1">
                      +3.5%
                    </span> -->
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-dollar-sign text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-md-2 cust-grid-col">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">LUCRO MENSAL</h6>

                <!-- Heading -->
                <div v-if="loadedLucro">
                  <span class="h2 mb-0"> ${{ lucro_mensal }} </span>
                </div>
                <div v-else>
                  <atom-spinner
                    :animation-duration="1000"
                    :size="40"
                    :color="'#ffffff'"
                  />
                </div>

                <!-- Badge
                    <span class="badge badge-soft-success mt-n1">
                      +3.5%
                    </span> -->
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-dollar-sign text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- fecha card -->
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import {AtomSpinner} from 'epic-spinners'

export default {
    name: 'TopBar',
    components: {
      AtomSpinner
    },
    data: function () {
      return {
        ganhos: 0,
        loadedLucro: false,
        porcentagem: 0
      }
    },
    mounted() {
      this.someDate()
      const config = {
            headers: {
        // Set your Authorization to 'JWT', not Bearer!!!
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }
      axios.get('list_gasto_lucro', config)
      .then(resp => {
        var apostado = resp.data.apostado
        var lucro = resp.data.lucro
        var lucro_hoje = resp.data.lucro_hoje
        var lucro_mensal = resp.data.lucro_mensal

        this.ganhos = resp.data.ganhos
        this.porcentagem = resp.data.porcentagem
        this.$store.state.apostado = apostado
        this.$store.state.lucro = lucro
        this.$store.state.lucro_hoje = lucro_hoje
        this.$store.state.lucro_mensal = lucro_mensal
        this.loadedLucro = true
      })
    },
    computed: {
      ...mapState({
        apostado: state => state.apostado,
        lucro: state => state.lucro,
        lucro_hoje: state => state.lucro_hoje,
        lucro_mensal: state => state.lucro_mensal,
      })
    },
    methods: {
      someDate: function () {
        console.log()
        return this.$moment(Date.now()).format("YYYY-MMMM-DD")
      }
    }

}
</script>

<style>
.cust-grid {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.cust-grid-col {
  float: none;
}
</style>