import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import axios from 'axios'
import {
  store
} from './store/index'
import Notify from 'vue2-notify'
import VModal from 'vue-js-modal'
import KProgress from 'k-progress'
import VueTheMask from 'vue-the-mask'

// Configuração do axios
axios.defaults.baseURL = 'https://betlol.com.br/painel/'
// Criar instância do axios
Vue.prototype.$http = axios

Vue.use(VueTheMask)
const moment = require('moment')
require('moment/locale/pt-br')

Vue.component('k-progress', KProgress)
Vue.use(Notify, {
  position: 'bottom-right',
  visibility: 7000
})
Vue.use(Vuex)
Vue.config.productionTip = false
Vue.use(VModal)
Vue.use(require('vue-moment'), {
  moment
})

require("flatpickr/dist/themes/dark.css")

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')