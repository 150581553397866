<template>
  <div class="container-fluid">
    <ListBalanco></ListBalanco>
  </div>
</template>

<script>
import ListBalanco from '@/components/Balanco/ListBalanco.vue'

export default {
  components: {
    ListBalanco
  }
}
</script>

<style>

</style>